import React from 'react';

const View2DIcon = ({ width = 24, height = 25 }) => {
  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 24 25"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M0.867429 20H9.15343C9.66171 20 9.98057 19.6699 9.98057 19.1647C9.98057 18.6698 9.66171 18.3401 9.15343 18.3401H2.51271V18.2883L6.41143 14.3712C8.78443 11.9895 9.48214 10.7524 9.48214 9.16475C9.48214 6.80433 7.45843 5 4.75629 5C2.63229 5 0.817714 6.18575 0.209143 7.99982C0.09 8.35121 0.0428571 8.63922 0.0428571 8.89711C0.0428571 9.41244 0.339 9.7523 0.827571 9.7523C1.29643 9.7523 1.49571 9.52588 1.64529 9.01055C1.725 8.69062 1.83471 8.40216 1.99414 8.13408C2.52257 7.21686 3.51 6.62886 4.75629 6.62886C6.37157 6.62886 7.65771 7.80441 7.65771 9.26799C7.65771 10.4537 7.18929 11.2575 5.21486 13.2679L0.548571 18.0619C0.129857 18.4948 0 18.7527 0 19.1342C0 19.6703 0.339 20 0.867429 20ZM12.8627 19.9792H16.9504C21.4174 19.9792 24 17.2266 24 12.5771C24 7.92804 21.4174 5.23706 16.9504 5.23706H12.8623C12.3137 5.23706 11.9653 5.60838 11.9653 6.18531V19.0203C11.9653 19.6079 12.3141 19.9792 12.8623 19.9792M13.7696 18.2887V6.90668H16.8206C20.2504 6.90668 22.155 8.96889 22.155 12.5873C22.155 16.2261 20.2504 18.2878 16.8206 18.2878L13.7696 18.2887Z"
        fill="white"
      />
    </svg>
  );
};

export default View2DIcon;
