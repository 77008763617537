import { fabric } from 'fabric';

import React, { useEffect, useRef, useState } from 'react';
import { Tooltip as ReactTooltip } from 'react-tooltip';
import CustomToolTip from 'components/customToolTip/CustomToolTip';

import { useTranslation } from 'react-i18next';

import AdjustIcon from 'icon/adjust';

import CrossIcon from 'icon/cross';

import {
  ToolTipTitleBarWrapper,
  ToolTipTitleText,
  ToolTipTitleIcon,
} from 'components/customToolTip/CustomToolTipStyles.css';

import { CustomTooltipWrapper } from './Text.css';
import {
  WrapperToolsFilters,
  CategoryTitleWrapper,
  CategoryTitle,
  SimpleButton,
  RightCenteringContainer,
} from './ImageFilters.css';

import { useStore, setState } from 'store/store';

import { IconContainer } from '../CanvasTools.css';

import CustomToogleButton from 'components/customToggleButton/CustomToggleButton';
import RangeSlider from 'components/customRangeSlider/RangeSlider';

import { filtersList } from 'store/lists';

export const addFilter = (filterIndex, parameter, value) => {
  if (filtersList[filterIndex].filterName === 'Brightness') {
    const filter = new fabric.Image.filters.Brightness({
      [parameter]: value,
    });
    // brightness: 0, // Adjust this value to control the effect
    return filter;
  } else if (filtersList[filterIndex].filterName === 'Contrast') {
    const filter = new fabric.Image.filters.Contrast({
      [parameter]: value,
    });
    // contrast: 0, // Adjust this value to control the effect
    return filter;
  } else if (filtersList[filterIndex].filterName === 'Saturation') {
    const filter = new fabric.Image.filters.Saturation({
      [parameter]: value,
    });
    // saturation: 0, // Adjust this value to control the effect
    return filter;
  } else if (filtersList[filterIndex].filterName === 'Vibrance') {
    const filter = new fabric.Image.filters.Vibrance({
      [parameter]: value,
    });
    // saturation: 0, // Adjust this value to control the effect
    return filter;
  } else if (filtersList[filterIndex].filterName === 'Hue') {
    const filter = new fabric.Image.filters.HueRotation({
      [parameter]: value,
    });
    // rotation: 0, // Adjust this value to control the effect
    return filter;
  } else if (filtersList[filterIndex].filterName === 'Gamma') {
    const filter = new fabric.Image.filters.Gamma({
      [parameter]: value,
    });
    // rotation: 0, // Adjust this value to control the effect
    return filter;
  } else if (filtersList[filterIndex].filterName === 'Blackwhite') {
    const filter = new fabric.Image.filters.BlackWhite();
    return filter;
  } else if (filtersList[filterIndex].filterName === 'Invert') {
    const filter = new fabric.Image.filters.Invert();
    return filter;
  } else if (filtersList[filterIndex].filterName === 'Sharpen') {
    const filter = new fabric.Image.filters.Convolute({
      matrix: [0, -1, 0, -1, 5, -1, 0, -1, 0],
    });
    return filter;
  } else if (filtersList[filterIndex].filterName === 'Noise') {
    const filter = new fabric.Image.filters.Noise({
      [parameter]: value,
    });
    return filter;
  } else if (filtersList[filterIndex].filterName === 'Blur') {
    const filter = new fabric.Image.filters.Blur({
      [parameter]: value,
    });
    return filter;
  } else if (filtersList[filterIndex].filterName === 'Pixelate') {
    const filter = new fabric.Image.filters.Pixelate({
      [parameter]: value,
    });
    return filter;
  }
};

const ImageFilters = ({ refParent }) => {
  const [
    refFabric,
    refTextureFabricArray,
    layers,
    fabricSelectedPrintArea,
    selectedLayers,

    qualityId,
    fabricSettings,
    worksapceToolToolTipImageFilters,
    forceRefreshingToolTipTools,
  ] = useStore((s) => [
    s.refFabric,
    s.refTextureFabricArray,
    s.layers,
    s.fabricSelectedPrintArea,
    s.selectedLayers,

    s.qualityId,
    s.fabricSettings,
    s.worksapceToolToolTipImageFilters,
    s.forceRefreshingToolTipTools,
  ]);

  const refTool = useRef();

  const { t } = useTranslation(['configurator']);
  const { i18n } = useTranslation();
  let langValue = i18n.language.substring(0, 2);

  const [activeFilters, setActiveFilters] = useState(false);

  const [brightness, setBrightness] = useState(0);
  const [Saturation, setSaturation] = useState(0);
  const [contrast, setContrast] = useState(0);
  const [hue, setHue] = useState(0);
  const [vibrance, setVibrance] = useState(0);
  const [gamma, setGamma] = useState([1, 1, 1]);
  const [blackAndWhite, setBlackAndWhite] = useState(false);
  const [invert, setInvert] = useState(false);
  const [sharpen, setSharpen] = useState(false);
  const [blur, setBlur] = useState(0);
  const [noise, setNoise] = useState(0);
  const [pixelate, setPixelate] = useState(1);

  useEffect(() => {
    if (selectedLayers.length > 0) {
      refTextureFabricArray.forEach((textureFabric) => {
        textureFabric.fabricRef.current.getObjects().forEach(function (object) {
          if (!selectedLayers.includes(object.id)) return;

          setActiveFilters(object.activeFilters);

          for (const filterElement in filtersList) {
            if (
              typeof filtersList[filterElement].defaultValue !== 'undefined'
            ) {
              const { filterName, defaultValue } = filtersList[filterElement];

              if (object.filterSettings[filterName]) {
                if (filterName === 'Brightness') {
                  setBrightness(object.filterSettings[filterName].value);
                } else if (filterName === 'Saturation') {
                  setSaturation(object.filterSettings[filterName].value);
                } else if (filterName === 'Contrast') {
                  setContrast(object.filterSettings[filterName].value);
                } else if (filterName === 'Hue') {
                  setHue(object.filterSettings[filterName].value);
                } else if (filterName === 'Vibrance') {
                  setVibrance(object.filterSettings[filterName].value);
                } else if (filterName === 'Gamma') {
                  setGamma(object.filterSettings[filterName].value);
                } else if (filterName === 'Blackwhite') {
                  setBlackAndWhite(object.filterSettings[filterName].value);
                } else if (filterName === 'Invert') {
                  setInvert(object.filterSettings[filterName].value);
                } else if (filterName === 'Sharpen') {
                  setSharpen(object.filterSettings[filterName].value);
                } else if (filterName === 'Blur') {
                  setBlur(object.filterSettings[filterName].value);
                } else if (filterName === 'Noise') {
                  setNoise(object.filterSettings[filterName].value);
                } else if (filterName === 'Pixelate') {
                  setPixelate(object.filterSettings[filterName].value);
                }
              } else {
                if (filterName === 'Brightness') {
                  setBrightness(defaultValue);
                } else if (filterName === 'Saturation') {
                  setSaturation(defaultValue);
                } else if (filterName === 'Contrast') {
                  setContrast(defaultValue);
                } else if (filterName === 'Hue') {
                  setHue(defaultValue);
                } else if (filterName === 'Vibrance') {
                  setVibrance(defaultValue);
                } else if (filterName === 'Gamma') {
                  setGamma(defaultValue);
                } else if (filterName === 'Blackwhite') {
                  setBlackAndWhite(defaultValue);
                } else if (filterName === 'Invert') {
                  setInvert(defaultValue);
                } else if (filterName === 'Sharpen') {
                  setSharpen(defaultValue);
                } else if (filterName === 'Blur') {
                  setBlur(defaultValue);
                } else if (filterName === 'Noise') {
                  setNoise(defaultValue);
                } else if (filterName === 'Pixelate') {
                  setPixelate(defaultValue);
                }
              }
            }
          }
        });
      });
    }
  }, [selectedLayers, layers, forceRefreshingToolTipTools]);

  const OpenTool = (value) => {
    setState({
      worksapceToolToolTipTextEdit: { open: false, newObject: false },
      worksapceToolToolTipImageEdit: { open: false },
      worksapceToolToolTipImageFilters: { open: value },
      worksapceToolToolTipImageFillColor: { open: false },
      worksapceToolToolTipTranslation: { open: false, objectType: null },
      worksapceToolToolTipTextFillColor: { open: false, newObject: false },
      worksapceToolToolTipTextBorderColor: { open: false },
      worksapceToolToolTipTextFont: { open: false },
      worksapceToolToolTipTextShadow: { open: false },
      worksapceToolToolTipClipPathShape: { open: false },
    });
  };

  const changeFilterValueRange = (
    canvasFabric,
    object,
    filterName,
    parameter,
    value
  ) => {
    const filterIndex = filtersList.findIndex(
      (item) => item.filterName === filterName
    );

    if (filterIndex) {
      if (object.filters[filterIndex]) {
        if (parameter !== null) {
          object.filters[filterIndex][parameter] = value;
        } else {
          if (value) {
          } else {
            object.filters.splice(filterIndex, 1);
          }
        }
      } else {
        object.filters[filterIndex] = addFilter(filterIndex, parameter, value);
      }
      object.applyFilters();
      canvasFabric.requestRenderAll();
    }
  };

  const changeFilterValueToggle = (canvasFabric, object, filterName, value) => {
    const filterIndex = filtersList.findIndex(
      (item) => item.filterName === filterName
    );

    if (filterIndex) {
      console.log(object.filters, object.filters[filterIndex]);

      if (object.filters[filterIndex]) {
        if (!value) {
          delete object.filters[filterIndex];
        }
      } else {
        object.filters[filterIndex] = addFilter(filterIndex, null, value);
      }
      object.applyFilters();
      canvasFabric.requestRenderAll();
    }
  };

  const handleActivateFilters = (value) => {
    if (selectedLayers.length > 0) {
      setActiveFilters(value);

      if (value) {
        if (refFabric.current) {
          refFabric.current.getObjects().forEach(function (object) {
            if (!selectedLayers.includes(object.id)) return;

            object.activeFilters = value;

            for (const filterName in object.filterSettings) {
              const filterIndex = filtersList.findIndex(
                (item) => item.filterName === filterName
              );

              if (filterIndex) {
                if (filtersList[filterIndex].type === 'BOOLEAN') {
                  if (object.filterSettings[filterName].value) {
                    object.filters[filterIndex] = addFilter(
                      filterIndex,
                      object.filterSettings[filterName].parameter,
                      object.filterSettings[filterName].value
                    );
                  }
                } else {
                  object.filters[filterIndex] = addFilter(
                    filterIndex,
                    object.filterSettings[filterName].parameter,
                    object.filterSettings[filterName].value
                  );
                }
              }
            }
            object.applyFilters();
          });
          refFabric.current.requestRenderAll();
        }

        refTextureFabricArray.forEach((textureFabric) => {
          textureFabric.fabricRef.current
            .getObjects()
            .forEach(function (object) {
              if (!selectedLayers.includes(object.id)) return;
              object.activeFilters = value;

              for (const filterName in object.filterSettings) {
                const filterIndex = filtersList.findIndex(
                  (item) => item.filterName === filterName
                );

                if (filterIndex) {
                  if (filtersList[filterIndex].type === 'BOOLEAN') {
                    if (object.filterSettings[filterName].value) {
                      object.filters[filterIndex] = addFilter(
                        filterIndex,
                        object.filterSettings[filterName].parameter,
                        object.filterSettings[filterName].value
                      );
                    }
                  } else {
                    object.filters[filterIndex] = addFilter(
                      filterIndex,
                      object.filterSettings[filterName].parameter,
                      object.filterSettings[filterName].value
                    );
                  }
                }
              }
              object.applyFilters();
            });

          textureFabric.fabricRef.current.requestRenderAll();
        });
      } else {
        if (refFabric.current) {
          refFabric.current.getObjects().forEach(function (object) {
            if (!selectedLayers.includes(object.id)) return;

            object.activeFilters = value;

            for (let i = object.filters.length - 1; i >= 0; i--) {
              if (object.filters[i]) {
                object.filters.splice(i, 1);
              }
            }

            object.applyFilters();
          });
          refFabric.current.requestRenderAll();
        }

        refTextureFabricArray.forEach((textureFabric) => {
          textureFabric.fabricRef.current
            .getObjects()
            .forEach(function (object) {
              if (!selectedLayers.includes(object.id)) return;

              object.activeFilters = value;

              for (let i = object.filters.length - 1; i >= 0; i--) {
                if (object.filters[i]) {
                  object.filters.splice(i, 1);
                }
              }

              object.applyFilters();
            });

          textureFabric.fabricRef.current.requestRenderAll();
        });
      }
    }
  };

  const handleFilterRange = (filterName, parameter, value, stateFunc) => {
    if (activeFilters) {
      if (selectedLayers.length > 0) {
        if (value !== '' && value !== '-') {
          if (refFabric.current) {
            refFabric.current.getObjects().forEach(function (object) {
              if (!selectedLayers.includes(object.id)) return;

              object.filterSettings = {
                ...object.filterSettings,
                [filterName]: { parameter, value },
              };

              changeFilterValueRange(
                refFabric.current,
                object,
                filterName,
                parameter,
                value
              );
            });
            refFabric.current.requestRenderAll();
          }

          refTextureFabricArray.forEach((textureFabric) => {
            textureFabric.fabricRef.current
              .getObjects()
              .forEach(function (object) {
                if (!selectedLayers.includes(object.id)) return;
                object.set('strokeWidth', parseFloat(value));

                object.filterSettings = {
                  ...object.filterSettings,
                  [filterName]: { parameter, value },
                };

                changeFilterValueRange(
                  textureFabric.fabricRef.current,
                  object,
                  filterName,
                  parameter,
                  value
                );
              });

            textureFabric.fabricRef.current.requestRenderAll();
          });
        }

        stateFunc(value);
      }
    }
  };

  const handleFilterToogle = (filterName, stateFunc, value) => {
    if (activeFilters) {
      if (selectedLayers.length > 0) {
        if (refFabric.current) {
          refFabric.current.getObjects().forEach(function (object) {
            if (!selectedLayers.includes(object.id)) return;

            object.filterSettings = {
              ...object.filterSettings,
              [filterName]: { parameter: null, value },
            };

            changeFilterValueToggle(
              refFabric.current,
              object,
              filterName,
              value
            );
          });
          refFabric.current.requestRenderAll();
        }

        refTextureFabricArray.forEach((textureFabric) => {
          textureFabric.fabricRef.current
            .getObjects()
            .forEach(function (object) {
              if (!selectedLayers.includes(object.id)) return;

              object.filterSettings = {
                ...object.filterSettings,
                [filterName]: { parameter: null, value },
              };

              changeFilterValueToggle(
                textureFabric.fabricRef.current,
                object,
                filterName,
                value
              );
            });

          textureFabric.fabricRef.current.requestRenderAll();
        });
        stateFunc(value);
      }
    }
  };

  const resetFilters = (group) => {
    if (selectedLayers.length > 0) {
      // let groupFilters = [];
      // if (group === 'Light') {
      //   groupFilters = ['Brightness', 'Contrast'];
      // } else if (group === 'Color') {
      //   groupFilters = ['Blackwhite', 'Hue', 'Saturation', 'Vibrance', 'Gamma'];
      // } else if (group === 'Details') {
      //   groupFilters = ['Sharpen', 'Blur', 'Noise', 'Pixelate'];
      // }

      let groupFilters = [
        'Brightness',
        'Contrast',
        'Blackwhite',
        'Hue',
        'Saturation',
        'Vibrance',
        'Gamma',
        'Sharpen',
        'Blur',
        'Noise',
        'Pixelate',
        'Invert',
      ];

      groupFilters.forEach((filterName) => {
        const filterIndex = filtersList.findIndex(
          (item) => item.filterName === filterName
        );

        if (filterIndex) {
          if (filterName === 'Brightness') {
            setBrightness(filtersList[filterIndex].defaultValue);
          } else if (filterName === 'Contrast') {
            setContrast(filtersList[filterIndex].defaultValue);
          } else if (filterName === 'Blackwhite') {
            setBlackAndWhite(filtersList[filterIndex].defaultValue);
          } else if (filterName === 'Hue') {
            setHue(filtersList[filterIndex].defaultValue);
          } else if (filterName === 'Saturation') {
            setSaturation(filtersList[filterIndex].defaultValue);
          } else if (filterName === 'Vibrance') {
            setVibrance(filtersList[filterIndex].defaultValue);
          } else if (filterName === 'Gamma') {
            setGamma(filtersList[filterIndex].defaultValue);
          } else if (filterName === 'Sharpen') {
            setSharpen(filtersList[filterIndex].defaultValue);
          } else if (filterName === 'Blur') {
            setBlur(filtersList[filterIndex].defaultValue);
          } else if (filterName === 'Noise') {
            setNoise(filtersList[filterIndex].defaultValue);
          } else if (filterName === 'Pixelate') {
            setPixelate(filtersList[filterIndex].defaultValue);
          } else if (filterName === 'Invert') {
            setInvert(filtersList[filterIndex].defaultValue);
          }

          if (refFabric.current) {
            refFabric.current.getObjects().forEach(function (object) {
              if (!selectedLayers.includes(object.id)) return;

              delete object.filterSettings[filterName];

              if (object.filters[filterIndex]) {
                delete object.filters[filterIndex];
              }

              object.applyFilters();
            });
            refFabric.current.requestRenderAll();
          }

          refTextureFabricArray.forEach((textureFabric) => {
            textureFabric.fabricRef.current
              .getObjects()
              .forEach(function (object) {
                if (!selectedLayers.includes(object.id)) return;

                delete object.filterSettings[filterName];

                if (object.filters[filterIndex]) {
                  delete object.filters[filterIndex];
                }

                object.applyFilters();
              });

            textureFabric.fabricRef.current.requestRenderAll();
          });
        }
      });
    }
  };

  return (
    <>
      <IconContainer
        ref={refTool}
        $stroke={false}
        data-tooltip-id={'toolipToolImageFilters'}
        onClick={() => OpenTool(!worksapceToolToolTipImageFilters.open)}
        $toolTip={worksapceToolToolTipImageFilters.open}
      >
        <AdjustIcon />
      </IconContainer>

      <ReactTooltip
        id={'toolipToolImageFilters'}
        place={'bottom-center'}
        content={t('rightSide.canvasTools.tooltip-imageFilters')}
        className="tooltip"
        offset={5}
        delayShow={500}
      />

      <CustomToolTip
        anchorRef={refTool}
        place={'bottom-middle'}
        offset={[5, 5, 0, 0]}
        open={worksapceToolToolTipImageFilters.open}
        setOpen={OpenTool}
        tip={true}
        anchor="left"
        alignParent={refParent.current}
        workspaceTool={true}
      >
        <CustomTooltipWrapper>
          <ToolTipTitleBarWrapper>
            <ToolTipTitleText>
              {t('rightSide.canvasTools.imageFilterBox.title')}
            </ToolTipTitleText>

            <ToolTipTitleIcon onClick={() => OpenTool(false)}>
              <CrossIcon />
            </ToolTipTitleIcon>
          </ToolTipTitleBarWrapper>

          <WrapperToolsFilters>
            <CustomToogleButton
              title={t('rightSide.canvasTools.imageFilterBox.enableFilters')}
              value={activeFilters}
              onChangeValue={handleActivateFilters}
            />
            {activeFilters ? (
              <>
                <CategoryTitle>
                  {t('rightSide.canvasTools.imageFilterBox.light')}
                </CategoryTitle>

                <RangeSlider
                  min={-1}
                  max={1}
                  step={0.01}
                  value={brightness}
                  text={t('rightSide.canvasTools.imageFilterBox.brightnes')}
                  symbol={''}
                  onChangeValue={(value) =>
                    handleFilterRange(
                      'Brightness',
                      'brightness',
                      value,
                      setBrightness
                    )
                  }
                />
                <RangeSlider
                  min={-1}
                  max={1}
                  step={0.01}
                  value={contrast}
                  text={t('rightSide.canvasTools.imageFilterBox.contrast')}
                  symbol={''}
                  onChangeValue={(value) =>
                    handleFilterRange(
                      'Contrast',
                      'contrast',
                      value,
                      setContrast
                    )
                  }
                />

                <CategoryTitle>
                  {t('rightSide.canvasTools.imageFilterBox.color')}
                </CategoryTitle>

                <CustomToogleButton
                  title={t('rightSide.canvasTools.imageFilterBox.blackWhite')}
                  value={blackAndWhite}
                  onChangeValue={(value) =>
                    handleFilterToogle('Blackwhite', setBlackAndWhite, value)
                  }
                />
                <CustomToogleButton
                  title={t('rightSide.canvasTools.imageFilterBox.invert')}
                  value={invert}
                  onChangeValue={(value) =>
                    handleFilterToogle('Invert', setInvert, value)
                  }
                />

                <RangeSlider
                  min={-1}
                  max={1}
                  step={0.01}
                  value={hue}
                  text={t('rightSide.canvasTools.imageFilterBox.hue')}
                  symbol={''}
                  onChangeValue={(value) =>
                    handleFilterRange('Hue', 'rotation', value, setHue)
                  }
                  styleBackground={{
                    background:
                      'linear-gradient(to right, red 0%, #ff0 17%, lime 33%, cyan 50%, blue 66%, #f0f 83%, red 100%)',
                  }}
                />
                <RangeSlider
                  min={-1}
                  max={1}
                  step={0.01}
                  value={Saturation}
                  text={t('rightSide.canvasTools.imageFilterBox.saturation')}
                  symbol={''}
                  onChangeValue={(value) =>
                    handleFilterRange(
                      'Saturation',
                      'saturation',
                      value,
                      setSaturation
                    )
                  }
                />
                <RangeSlider
                  min={-1}
                  max={1}
                  step={0.01}
                  value={vibrance}
                  text={t('rightSide.canvasTools.imageFilterBox.vibrance')}
                  symbol={''}
                  onChangeValue={(value) =>
                    handleFilterRange(
                      'Vibrance',
                      'vibrance',
                      value,
                      setVibrance
                    )
                  }
                />
                <RangeSlider
                  min={0}
                  max={2}
                  step={0.001}
                  value={gamma[0]}
                  text={t('rightSide.canvasTools.imageFilterBox.gammaRed')}
                  symbol={''}
                  onChangeValue={(value) =>
                    handleFilterRange(
                      'Gamma',
                      'gamma',
                      [parseFloat(value), gamma[1], gamma[2]],
                      setGamma
                    )
                  }
                  styleBackground={{
                    background: 'linear-gradient(to right, black 0%, red 100%)',
                  }}
                />
                <RangeSlider
                  min={0}
                  max={2}
                  step={0.001}
                  value={gamma[1]}
                  text={t('rightSide.canvasTools.imageFilterBox.gammaGreen')}
                  symbol={''}
                  onChangeValue={(value) =>
                    handleFilterRange(
                      'Gamma',
                      'gamma',
                      [gamma[0], parseFloat(value), gamma[2]],
                      setGamma
                    )
                  }
                  styleBackground={{
                    background:
                      'linear-gradient(to right, black 0%, green 100%)',
                  }}
                />
                <RangeSlider
                  min={0}
                  max={2}
                  step={0.001}
                  value={gamma[2]}
                  text={t('rightSide.canvasTools.imageFilterBox.gammaBlue')}
                  symbol={''}
                  onChangeValue={(value) =>
                    handleFilterRange(
                      'Gamma',
                      'gamma',
                      [gamma[0], gamma[1], parseFloat(value)],
                      setGamma
                    )
                  }
                  styleBackground={{
                    background:
                      'linear-gradient(to right, black 0%, blue 100%)',
                  }}
                />

                <CategoryTitle>
                  {t('rightSide.canvasTools.imageFilterBox.details')}
                </CategoryTitle>

                <CustomToogleButton
                  title={t('rightSide.canvasTools.imageFilterBox.sharpen')}
                  value={sharpen}
                  onChangeValue={(value) =>
                    handleFilterToogle('Sharpen', setSharpen, value)
                  }
                />
                <RangeSlider
                  min={0}
                  max={1}
                  step={0.01}
                  value={blur}
                  text={t('rightSide.canvasTools.imageFilterBox.blur')}
                  symbol={''}
                  onChangeValue={(value) =>
                    handleFilterRange('Blur', 'blur', value, setBlur)
                  }
                />
                {/* <RangeSlider
                  min={0}
                  max={100}
                  step={1}
                  value={noise}
                  text={'Noise'}
                  symbol={''}
                  onChangeValue={(value) =>
                    handleFilterRange('Noise', 'noise', value, setNoise)
                  }
                />

                <RangeSlider
                  min={1}
                  max={10}
                  step={1}
                  value={pixelate}
                  text={'Pixelate'}
                  symbol={''}
                  onChangeValue={(value) =>
                    handleFilterRange(
                      'Pixelate',
                      'blocksize',
                      value,
                      setPixelate
                    )
                  }
                /> */}

                <SimpleButton onClick={() => resetFilters('Details')}>
                  {t('rightSide.canvasTools.imageFilterBox.reset')}
                </SimpleButton>
              </>
            ) : null}
          </WrapperToolsFilters>
        </CustomTooltipWrapper>
      </CustomToolTip>
    </>
  );
};

export default ImageFilters;
