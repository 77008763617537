import styled, { css } from 'styled-components';

export const WrapperToolsFilters = styled.div`
  background-color: ${({ theme }) => theme.color.toolTipBackgroundElement};
  padding: 10px;

  display: grid;
  gap: 10px;
  position: relative;
  border-radius: 0px 0px 3px 3px;

  overflow-y: auto;
  max-height: 350px;
`;

export const CategoryTitleWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

export const CategoryTitle = styled.div`
  user-select: none;
  padding: 10px 0px 0px 0px;
  color: ${({ theme }) => theme.color.toolTipVeryDark};
  font-size: 16px;
  font-weight: bold;
  flex-basis: 60%;
  text-align: center;
`;

export const SimpleButton = styled.div`
  margin-top: 10px;
  flex-basis: 20%;
  background-color: ${({ theme }) => theme.color.toolTipBackground};
  color: ${({ theme }) => theme.color.toolTipDark};
  font-size: 12px;

  display: grid;
  justify-content: center;
  align-items: center;
  padding: 0px 10px;

  cursor: pointer;

  height: 35px;

  transition: ${({ theme }) => theme.parameter.transition};
  &:hover {
    background-color: ${({ theme }) => theme.color.selecboxHover};
  }
`;

export const RightCenteringContainer = styled.div`
  flex-basis: 20%;
`;
