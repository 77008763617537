export const removeElementFromLayers = (layers, layersInitial, elementId) => {
  Object.keys(layers).forEach((sectionKey) => {
    layers[sectionKey] = layers[sectionKey].filter(
      (item) => item.id !== elementId
    );
  });

  layersInitial.forEach((sectionKey) => {
    layersInitial[sectionKey] = layersInitial.filter(
      (item) => item.id !== elementId
    );
  });
};

export const lockElementInLayers = (layers, elementId, islocked) => {
  Object.keys(layers).forEach((sectionKey) => {
    layers[sectionKey].forEach((item) => {
      if (item.id === elementId) {
        item.islocked = islocked;
      }
    });
  });
};

export const visibilityElementInLayers = (layers, elementId, visible) => {
  Object.keys(layers).forEach((sectionKey) => {
    layers[sectionKey].forEach((item) => {
      if (item.id === elementId) {
        item.visible = visible;
      }
    });
  });
};

export const addNewLayer = (
  elementId,
  group,
  elementType,
  islocked,
  title = 'title',
  layerProps,
  visible
) => {
  const layer = {
    id: elementId,
    title: title,
    groupId: group,
    type: elementType,
    islocked: islocked,
    props: { ...layerProps },
    visible: visible,
    // props: {
    //   text: 'New Image added',
    // },
  };

  return layer;
};

export const findLayerItem = (layers, elementId) => {
  let layerItem;
  for (let key in layers) {
    // eslint-disable-next-line no-loop-func
    layers[key].forEach((item) => {
      if (item.id === elementId) {
        layerItem = item;
        return;
      }
    });
  }
  return layerItem;
};

export const arraysEqual = (arr1, arr2) => {
  if (arr1.length !== arr2.length) return false;

  const sortedArr1 = [...arr1].sort();
  const sortedArr2 = [...arr2].sort();

  for (let i = 0; i < sortedArr1.length; i++) {
    if (sortedArr1[i] !== sortedArr2[i]) {
      return false;
    }
  }

  return true;
};
