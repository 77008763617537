export function isFocusNotOnSpecifiedElements() {
  const focusedElement = document.activeElement;
  if (
    focusedElement.tagName === 'TEXTAREA' ||
    (focusedElement.tagName === 'INPUT' &&
      (focusedElement.type === 'text' || focusedElement.type === 'range'))
  ) {
    return false; // Element jest textarea lub input (text/range)
  }
  return true; // Element nie jest textarea ani input (text/range)
}
