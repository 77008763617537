import i18n from 'i18next';
import Backend from 'i18next-http-backend';
import LanguageDetector from 'i18next-browser-languagedetector';
import { initReactI18next } from 'react-i18next';

i18n
  .use(Backend)
  .use(LanguageDetector)
  .use(initReactI18next)
  .init({
    defaultLanguage: 'en',
    otherLanguages: ['pl'],
    fallbackLng: 'en',
    debug: false,
    defaultNS: 'translation',
    //saveMissing: true,

    interpolation: {
      escapeValue: false, // not needed for react as it escapes by default
    },

    backend: {
      loadPath: (lng, namespaces) => {
        const language =
          typeof lng === 'string' ? lng.split('-')[0] : lng[0].split('-')[0];
        return `/translate/${language}/${namespaces[0]}.json`;
      },
    },

    /* lng: locale.split("-")[0], */
  });

export default i18n;
