import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from 'pages/App';
import reportWebVitals from './reportWebVitals';

import './i18n';
import { Suspense } from 'react';

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <>
    <Suspense fallback={null}>
      <App />
    </Suspense>
  </>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
