import React, { useEffect, useLayoutEffect, useRef, useState } from 'react';

import { Wrapper, Tip } from './CustomToolTip.css';
import { useSpring, animated } from 'react-spring';

import { useStore } from 'store/store';

const CustomToolTip = ({
  children,
  anchorRef,
  place = 'bottom-start',
  offset = [0, 0, 0, 0],
  open,
  setOpen,
  tip = false,
  anchor = 'left',
  alignParent = document.body,
  workspaceTool = false,
  modal,
}) => {
  const ref = useRef(null);
  const refTip = useRef(null);
  const [isVisible, setIsVisible] = useState(false);

  const [refFabricContainer, refThree] = useStore((s) => [
    s.refFabricContainer,
    s.refThree,
  ]);

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (workspaceTool) {
        if (
          anchorRef.current &&
          !anchorRef.current.contains(event.target) &&
          ref.current &&
          !ref.current.contains(event.target) &&
          refFabricContainer.current &&
          !refFabricContainer.current.contains(event.target) &&
          refThree.current &&
          !refThree.current.contains(event.target)
        ) {
          animate({
            opacity: 0,
            onRest: () => {
              setIsVisible(false);
              setOpen(false);
            },
            default: true,
          });
        }
      } else {
        if (
          anchorRef.current &&
          !anchorRef.current.contains(event.target) &&
          ref.current &&
          !ref.current.contains(event.target)
        ) {
          animate({
            opacity: 0,
            onRest: () => {
              setIsVisible(false);
              setOpen(false);
            },
            default: true,
          });
        }
      }
    };

    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, [isVisible]);

  useEffect(() => {
    if (open) {
      setIsVisible(true);
      animate({
        opacity: 1,
        onRest: () => {},
        default: true,
      });
    } else {
      animate({
        opacity: 0,
        onRest: () => {
          setIsVisible(false);
        },
        default: true,
      });
    }
  }, [open]);

  useLayoutEffect(() => {
    const tipWidth = 5;
    if (ref.current) {
      const anchorEl = anchorRef.current;
      const anchorBoundry = anchorEl.getBoundingClientRect();
      const toolTipBoundry = ref.current.getBoundingClientRect();

      if (place === 'bottom-end') {
        ref.current.style.top =
          anchorBoundry.top + anchorBoundry.height + offset[0] + 'px';

        ref.current.style.right =
          Math.round(
            document.body.offsetWidth - anchorBoundry.right + offset[1]
          ) + 'px';

        if (refTip.current) {
          const tipRight =
            document.body.offsetWidth -
            anchorBoundry.right +
            anchorBoundry.width / 2 -
            offset[1] -
            tipWidth;

          refTip.current.style.right = tipRight + 'px';
        }
      } else if (place === 'bottom-middle') {
        ref.current.style.top =
          anchorBoundry.top + anchorBoundry.height + offset[0] + 'px';

        if (anchor === 'right') {
          // console.log(
          //   anchorEl.getBoundingClientRect().left - anchorEl.offsetLeft,

          //   document.body.offsetWidth - anchorEl.parentElement.offsetLeft
          // );

          let posHorizontal = Math.round(
            document.body.offsetWidth -
              anchorBoundry.left -
              anchorBoundry.width / 2 -
              toolTipBoundry.width / 2 +
              offset[1]
          );

          // console.log(posHorizontal);

          if (posHorizontal < offset[1]) {
            posHorizontal = offset[1];
          }

          ref.current.style.right = posHorizontal + 'px';

          if (refTip.current) {
            const tipRight =
              document.body.offsetWidth -
              anchorBoundry.right +
              anchorBoundry.width / 2 -
              tipWidth -
              posHorizontal;

            refTip.current.style.right = tipRight + 'px';
          }
        } else if (anchor === 'left') {
          // console.log(alignParent.getBoundingClientRect());

          // let posHorizontal = Math.round(
          //   anchorEl.offsetLeft +
          //     anchorBoundry.width / 2 -
          //     toolTipBoundry.width / 2 +
          //     offset[1]
          // );

          // console.log(alignParent.getBoundingClientRect().left);

          let posHorizontal = Math.round(
            anchorBoundry.left +
              anchorBoundry.width / 2 -
              toolTipBoundry.width / 2 +
              offset[1]
          );

          // console.log(posHorizontal);

          if (
            posHorizontal <
            alignParent.getBoundingClientRect().left + offset[1]
          ) {
            posHorizontal =
              alignParent.getBoundingClientRect().left + offset[1];
          }

          ref.current.style.left = posHorizontal + 'px';

          if (refTip.current) {
            // const tipPos =
            //   anchorEl.offsetLeft +
            //   anchorBoundry.width / 2 -
            //   tipWidth -
            //   posHorizontal;

            const tipPos =
              anchorBoundry.left +
              anchorBoundry.width / 2 -
              tipWidth -
              posHorizontal;

            refTip.current.style.left = tipPos + 'px';
          }
        }
      } else if (place === 'bottom-middle-center') {
        // ref.current.style.top =
        //   anchorBoundry.top + anchorBoundry.height + offset[0] + 'px';

        const styleTop = anchorBoundry.top + anchorBoundry.height + offset[0];

        if (
          anchorBoundry.top +
            anchorBoundry.height +
            ref.current.getBoundingClientRect().height >
          window.innerHeight
        ) {
          ref.current.style.top =
            styleTop -
            ref.current.getBoundingClientRect().height -
            anchorBoundry.height +
            'px';
        } else {
          ref.current.style.top = styleTop + 'px';
        }

        if (anchor === 'right') {
          // console.log(
          //   anchorEl.getBoundingClientRect().left - anchorEl.offsetLeft,

          //   document.body.offsetWidth - anchorEl.parentElement.offsetLeft
          // );

          let posHorizontal = Math.round(
            document.body.offsetWidth -
              anchorBoundry.left -
              anchorBoundry.width / 2 -
              toolTipBoundry.width / 2 +
              offset[1]
          );

          // console.log(posHorizontal);

          if (posHorizontal < offset[1]) {
            posHorizontal = offset[1];
          }

          ref.current.style.right = posHorizontal + 'px';

          if (refTip.current) {
            const tipRight =
              document.body.offsetWidth -
              anchorBoundry.right +
              anchorBoundry.width / 2 -
              tipWidth -
              posHorizontal;

            refTip.current.style.right = tipRight + 'px';
          }
        } else if (anchor === 'left') {
          // console.log(alignParent.getBoundingClientRect());

          // let posHorizontal = Math.round(
          //   anchorEl.offsetLeft +
          //     anchorBoundry.width / 2 -
          //     toolTipBoundry.width / 2 +
          //     offset[1]
          // );

          // console.log(alignParent.getBoundingClientRect().left);

          let posHorizontal = Math.round(
            anchorBoundry.left -
              ref.current.getBoundingClientRect().width / 2 +
              anchorBoundry.width / 2 +
              offset[1]
          );

          // console.log(posHorizontal);

          // if (
          //   posHorizontal <
          //   alignParent.getBoundingClientRect().left + offset[1]
          // ) {
          //   posHorizontal =
          //     alignParent.getBoundingClientRect().left + offset[1];
          // }

          ref.current.style.left = posHorizontal + 'px';

          if (refTip.current) {
            // const tipPos =
            //   anchorEl.offsetLeft +
            //   anchorBoundry.width / 2 -
            //   tipWidth -
            //   posHorizontal;

            const tipPos =
              anchorBoundry.left +
              anchorBoundry.width / 2 -
              tipWidth -
              posHorizontal;

            refTip.current.style.left = tipPos + 'px';
          }
        }
      } else if (place === 'bottom-middle-flex') {
        // console.log(alignParent.offsetTop, anchorEl.offsetTop);

        ref.current.style.top =
          alignParent.offsetTop + anchorBoundry.height + offset[0] + 'px';

        let posHorizontal = Math.round(
          alignParent.offsetLeft +
            anchorEl.offsetLeft +
            anchorBoundry.width / 2 -
            toolTipBoundry.width / 2 +
            offset[1]
        );

        // console.log(posHorizontal);

        if (posHorizontal < alignParent.offsetLeft + offset[1]) {
          posHorizontal = alignParent.offsetLeft + offset[1];
        }

        ref.current.style.left = posHorizontal + 'px';

        if (refTip.current) {
          // const tipPos =
          //   anchorEl.offsetLeft +
          //   anchorBoundry.width / 2 -
          //   tipWidth -
          //   posHorizontal;
          // console.log(posHorizontal, anchorEl.offsetLeft);

          const tipPos =
            alignParent.offsetLeft +
            anchorEl.offsetLeft +
            anchorBoundry.width / 2 -
            tipWidth -
            posHorizontal;

          refTip.current.style.left = tipPos + 'px';
        }
      } else if (place === 'bottom-middle-relative') {
        const styleTop =
          anchorBoundry.top -
          modal.parentElement.getBoundingClientRect().top +
          anchorBoundry.height;

        if (
          anchorBoundry.top +
            modal.parentElement.getBoundingClientRect().top +
            ref.current.getBoundingClientRect().height >
          window.innerHeight
        ) {
          ref.current.style.top =
            styleTop -
            ref.current.getBoundingClientRect().height -
            anchorBoundry.height +
            'px';
        } else {
          ref.current.style.top = styleTop + 'px';
        }

        let posHorizontal = Math.round(
          alignParent.offsetLeft +
            anchorEl.offsetLeft +
            anchorBoundry.width / 2 -
            toolTipBoundry.width / 2 +
            offset[1]
        );

        // console.log(posHorizontal);

        if (posHorizontal < alignParent.offsetLeft + offset[1]) {
          posHorizontal = alignParent.offsetLeft + offset[1];
        }

        // ref.current.style.left = posHorizontal + 'px';

        if (refTip.current) {
          // const tipPos =
          //   anchorEl.offsetLeft +
          //   anchorBoundry.width / 2 -
          //   tipWidth -
          //   posHorizontal;
          // console.log(posHorizontal, anchorEl.offsetLeft);

          const tipPos =
            alignParent.offsetLeft +
            anchorEl.offsetLeft +
            anchorBoundry.width / 2 -
            tipWidth -
            posHorizontal;

          refTip.current.style.left = tipPos + 'px';
        }
      } else if (place === 'bottom-start') {
        ref.current.style.top =
          anchorBoundry.top + anchorBoundry.height + offset[0] + 'px';

        if (anchor === 'left') {
          ref.current.style.left =
            Math.round(anchorBoundry.left + offset[3]) + 'px';

          if (refTip.current) {
            const tipRight =
              anchorBoundry.left +
              anchorBoundry.width / 2 -
              offset[3] -
              tipWidth;

            refTip.current.style.left = tipRight + 'px';
          }
        }
      } else if (place === 'left-start') {
        if (anchor === 'right') {
        } else if (anchor === 'left') {
          let posHorizontal = Math.round(
            anchorEl.offsetLeft + anchorBoundry.width + offset[3]
          );

          // console.log(posHorizontal);

          if (
            posHorizontal <
            alignParent.getBoundingClientRect().offsetLeft + offset[3]
          ) {
            posHorizontal =
              alignParent.getBoundingClientRect().offsetLeft + offset[3];
          }

          ref.current.style.left = posHorizontal + 'px';

          let posVertical = Math.round(anchorEl.offsetTop);

          ref.current.style.top = posVertical + 'px';

          if (refTip.current) {
            const tipPos = -10;

            const tipPosTop = anchorBoundry.height / 2 - tipWidth;

            refTip.current.style.left = tipPos + 'px';
            refTip.current.style.top = tipPosTop + 'px';
            refTip.current.style.transform = 'rotate(-90deg)';
          }
        }
      }
    }
  }, [isVisible]);

  const [style, animate] = useSpring(
    () => ({
      opacity: 0,
      config: {
        clamp: true,
        duration: 150,
      },
    }),
    []
  );

  return (
    <>
      {isVisible ? (
        <Wrapper style={{ ...style }} ref={ref}>
          {tip ? <Tip ref={refTip} /> : null}

          {children}
        </Wrapper>
      ) : null}
    </>
  );
};

export default CustomToolTip;
