import { fabric } from 'fabric';

import { enumQuality, enumWorkspaceModes } from 'store/enums';
import { setState, useStore, getState } from 'store/store';
import { resolutionProps, removeElementFromFabric } from './utils';

function roundToNiceNumber(value) {
  const exponent = Math.floor(Math.log10(value));
  const fraction = value / Math.pow(10, exponent);
  let niceFraction;

  if (fraction <= 1) {
    niceFraction = 1;
  } else if (fraction <= 2) {
    niceFraction = 2;
  } else if (fraction <= 5) {
    niceFraction = 5;
  } else {
    niceFraction = 10;
  }

  return niceFraction * Math.pow(10, exponent);
}

function calculateGridLines(
  calibrationSize,
  maxLines = 20,
  workspacePx = 1024
) {
  // Oblicz minimalny odstęp jako floor(calibrationSize / maxLines)
  let unitSpacing = Math.floor(calibrationSize / maxLines);

  // Upewnij się, że unitSpacing jest co najmniej 1
  unitSpacing = Math.max(1, unitSpacing);

  // Oblicz liczbę linii
  const numLines = Math.floor(calibrationSize / unitSpacing);

  // Oblicz odstęp w pikselach
  const pixelsPerUnit = workspacePx / calibrationSize;
  const pixelSpacing = unitSpacing * pixelsPerUnit;

  return {
    unitSpacing,
    pixelSpacing,
    numLines,
  };
}

const removeGroupWithElements = (fabricRef, idGroup) => {
  fabricRef.getObjects().forEach(function (group) {
    if (group.id !== idGroup) return; // find specyfic object

    group.forEachObject(function (object) {
      fabricRef.remove(object);
    });

    fabricRef.remove(group);
  });
};

export const addGrid = (fabricRef, fabricSettings, calibrationSize) => {
  // remove current grids
  removeGroupWithElements(fabricRef.current, 'grid');
  removeGroupWithElements(fabricRef.current, 'labelsTop');
  removeGroupWithElements(fabricRef.current, 'labelsLeft');

  const gridLines = [];
  const gridLabelsLeft = [];
  const gridLabelsTop = [];
  if (fabricSettings.grid.visible) {
    const canvasHeight = fabricSettings.workspaceSize.height;

    const gridOption = {
      type: 'line',
      stroke: fabricSettings.grid.color,
      strokeWidth: fabricSettings.grid.strokeWidth,
    };

    const gridSpacing = calculateGridLines(calibrationSize);

    for (let i = 1; i <= gridSpacing.numLines + 1; i++) {
      const position = i * gridSpacing.pixelSpacing;

      // console.log(position, canvasHeight);
      // Upewnij się, że linia mieści się w przestrzeni roboczej
      if (position < canvasHeight) {
        gridLines.push(
          new fabric.Line([position, 0, position, canvasHeight + 2], gridOption)
        );

        gridLines.push(
          new fabric.Line([0, position, canvasHeight + 2, position], gridOption)
        );
      }
    }

    for (let i = 0; i <= gridSpacing.numLines; i++) {
      const position = i * gridSpacing.pixelSpacing;

      gridLabelsTop.push(
        new fabric.Text(`${i * gridSpacing.unitSpacing}`, {
          fill: fabricSettings.grid.color,
          fontSize: 12,
          originX: 'center',
          originY: 'center',
          left: position,
          top: 0,
          textAlign: 'center',
        })
      );

      gridLabelsLeft.push(
        new fabric.Text(`${i * gridSpacing.unitSpacing}`, {
          fill: fabricSettings.grid.color,
          fontSize: 12,
          originX: 'right',
          originY: 'center',
          left: 0,
          top: position,
          textAlign: 'right',
        })
      );
    }

    let positionTop = 0;
    if (gridSpacing.numLines * gridSpacing.pixelSpacing > 1024 - 20) {
      positionTop = -20;
    }

    gridLabelsTop.push(
      new fabric.Text(`${calibrationSize.toFixed(1)}`, {
        fill: fabricSettings.grid.color,
        fontSize: 12,
        originX: 'center',
        originY: 'center',
        left: 1025,
        top: positionTop,
        textAlign: 'center',
      })
    );

    let positionLeft = 0;
    if (gridSpacing.numLines * gridSpacing.pixelSpacing > 1024 - 8) {
      positionLeft = -20;
    }

    gridLabelsLeft.push(
      new fabric.Text(`${calibrationSize.toFixed(1)}`, {
        fill: fabricSettings.grid.color,
        fontSize: 12,
        originX: 'right',
        originY: 'center',
        left: positionLeft,
        top: 1025,
        textAlign: 'right',
      })
    );
  }

  const gridGroup = new fabric.Group(gridLines, {
    left: -0.5,
    top: -0.5,
    selectable: false,
    evented: false,
    objectCaching: false,
    id: 'grid',
    centeredScaling: false,
    originX: 'left',
    originY: 'top',
    type: 'SKIP',
  });

  fabricRef.current.add(gridGroup);
  gridGroup.moveTo(0);

  // Group labels
  const labelTopGroup = new fabric.Group(gridLabelsTop, {
    left: -4,
    top: -8,
    selectable: false,
    evented: false,
    objectCaching: true,
    id: 'labelsTop',
    type: 'SKIP',
    originX: 'left',
    originY: 'bottom',
  });

  fabricRef.current.add(labelTopGroup);
  labelTopGroup.moveTo(1);

  const labelLeftGroup = new fabric.Group(gridLabelsLeft, {
    left: -9,
    top: -8,
    selectable: false,
    evented: false,
    objectCaching: true,
    id: 'labelsLeft',
    type: 'SKIP',
    originX: 'right',
    originY: 'top',
  });

  fabricRef.current.add(labelLeftGroup);
  labelLeftGroup.moveTo(1);
};

export const addWorkspaceBorder = (fabricRef, fabricSettings) => {
  const canvasWidth = fabricSettings.workspaceSize.width;
  const canvasHeight = fabricSettings.workspaceSize.height;

  const { strokeWidth, fill, color } = fabricSettings.workspaceSizeBorder;

  const borderSize = {
    width: canvasWidth + strokeWidth,
    height: canvasHeight + strokeWidth,
  };

  const borderPosition = {
    left: canvasWidth / 2 + 0.5,
    top: canvasHeight / 2 + 0.5,
  };

  // console.log(
  //   borderSize.width,
  //   borderSize.height,
  //   borderPosition.left,
  //   borderPosition.top
  // );

  const workspaceBorder = new fabric.Rect({
    left: borderPosition.left,
    top: borderPosition.top,
    fill: fill,
    stroke: color,
    evented: false,
    selectable: false,
    strokeWidth: strokeWidth,
    width: borderSize.width,
    height: borderSize.height,
    objectCaching: false,
    id: 'workspaceBorder',
    // type: 'SKIP',
    centeredScaling: true,
    originX: 'center',
    originY: 'center',
  });

  fabricRef.current.add(workspaceBorder);

  workspaceBorder.moveTo(1);
};

export const backgroundImage = (
  fabricRef,
  selectedProduct,
  fabricSelectedPrintArea,
  qualityId,
  fabricSettings,
  selectedProductOptions
) => {
  const findPrintAreaTexture = selectedProduct.printAreas.find(
    (item) => item.id === fabricSelectedPrintArea
  );

  if (findPrintAreaTexture) {
    const findTexture = findPrintAreaTexture.textures.find(
      (item) => item.idColor === selectedProductOptions.colorId
    );

    if (findTexture) {
      const findColorMap = findPrintAreaTexture.textures.types.findIndex(
        (item) => item === 'color'
      );

      const { textureSize, workspaceWidth, workspaceHeight } = resolutionProps(
        qualityId,
        fabricSettings
      );

      const textureUrl =
        findPrintAreaTexture.textures[enumQuality.LOW_QUALITY][findColorMap];

      fabric.Image.fromURL(
        textureUrl,
        function (image) {
          const imgObject = image.set({
            left: workspaceWidth / 2,
            top: workspaceHeight / 2,
            width: image.width,
            height: image.height,
            scaleX: workspaceWidth / image.width,
            scaleY: workspaceHeight / image.height,
            angle: 0,
            centeredScaling: true,
            originX: 'center',
            originY: 'center',

            selectable: false,
            evented: false,
            objectCaching: false,
          });

          const backgroundImages = new fabric.Group([imgObject], {
            selectable: false,
            evented: false,
            objectCaching: false,
            id: 'backgroundImages',
            type: 'SKIP',
          });

          fabricRef.current.add(backgroundImages);

          backgroundImages.moveTo(2);
        },
        { crossOrigin: 'Anonymous' }
      );
    }
  } else {
    const backgroundImages = new fabric.Group([], {
      selectable: false,
      evented: false,
      objectCaching: false,
      id: 'backgroundImages',
      type: 'SKIP',
    });
    fabricRef.current.add(backgroundImages);

    backgroundImages.moveTo(2);
  }
};

export const clipPath = (fabricRef) => {
  const cornerSize = 30;

  const workspacesMode = getState().workspacesMode;
  const fabricSelectedPrintArea = getState().fabricSelectedPrintArea;

  // remove current clipath
  removeElementFromFabric(
    fabricRef.current,
    `clipPath_${fabricSelectedPrintArea}`
  );

  const findModel = getState().currentClipPathList.find(
    (item) => item.idVariant === getState().selectedProductOptionsVariantId
  );

  const findPrintAreaClipPath = findModel.clipPath.find(
    (texture) => texture.idPrintArea === fabricSelectedPrintArea
  );

  if (findPrintAreaClipPath) {
    if (findPrintAreaClipPath.clipPath.type === 'RECT') {
      const {
        left,
        top,
        height,
        width,
        angle = 0,
        scaleX = 1,
        scaleY = 1,
      } = findPrintAreaClipPath.clipPath.data;

      // ----- Store for clipping objects
      const clipPath = new fabric.Rect({
        height: height,
        width: width,
        top: top,
        left: left,
        scaleX: scaleX,
        scaleY: scaleY,
        angle: angle,
        id: `clipPath_${fabricSelectedPrintArea}`,
        type: 'RECT',
        // type: 'SKIP',
        centeredScaling: true,
        originX: 'center',
        originY: 'center',
        absolutePositioned: true,
      });
      fabricRef.current.clippingShape = clipPath;

      // ----- Add to workspace
      const clipPathArea = new fabric.Rect({
        selectable: workspacesMode.mode === enumWorkspaceModes.EDIT_PRINT_AREAS,
        evented: workspacesMode.mode === enumWorkspaceModes.EDIT_PRINT_AREAS,
        height: height,
        width: width,
        top: top,
        left: left,
        scaleX: scaleX,
        scaleY: scaleY,
        angle: angle,
        id: `clipPath_${fabricSelectedPrintArea}`,
        type: 'RECT',
        // type: 'SKIP',
        cornerSize: cornerSize,
        centeredScaling: true,
        originX: 'center',
        originY: 'center',
        absolutePositioned: true,
        stroke: 'red',
        strokeWidth: 0.75,
        strokeUniform: true,
        fill: '',
        objectCaching: false,

        // strokeDashArray: [4, 4],
      });

      fabricRef.current.add(clipPathArea);
      fabricRef.current.bringToFront(clipPathArea);
      if (
        getState().selectedLayers.includes(
          `clipPath_${fabricSelectedPrintArea}`
        )
      ) {
        fabricRef.current.setActiveObject(clipPathArea);
        clipPathArea.setControlsVisibility({
          mrCustom: true,
          mbCustom: true,
          brCustom: true,
          mtr: true,
          unlock: false,
          lock: false,
          clone: false,
          deleteControl: false,
        });
      }
    } else if (findPrintAreaClipPath.clipPath.type === 'CIRCLE') {
      const {
        left,
        top,
        radius,
        angle = 0,
        scaleX = 1,
        scaleY = 1,
      } = findPrintAreaClipPath.clipPath.data;

      // ----- Store for clipping objects
      const clipPath = new fabric.Circle({
        radius: radius,
        top: top,
        left: left,
        scaleX: scaleX,
        scaleY: scaleY,
        angle: angle,
        id: `clipPath_${fabricSelectedPrintArea}`,
        type: 'CIRCLE',
        // type: 'SKIP',
        centeredScaling: true,
        originX: 'center',
        originY: 'center',
        absolutePositioned: true,
      });

      fabricRef.current.clippingShape = clipPath;

      // ----- Add to workspace
      const clipPathArea = new fabric.Circle({
        selectable: workspacesMode.mode === enumWorkspaceModes.EDIT_PRINT_AREAS,
        evented: workspacesMode.mode === enumWorkspaceModes.EDIT_PRINT_AREAS,
        radius: radius,
        top: top,
        left: left,
        scaleX: scaleX,
        scaleY: scaleY,
        angle: angle,
        id: `clipPath_${fabricSelectedPrintArea}`,
        type: 'CIRCLE',
        // type: 'SKIP',
        cornerSize: cornerSize,
        centeredScaling: true,
        originX: 'center',
        originY: 'center',
        absolutePositioned: true,
        stroke: 'red',
        fill: '',
        objectCaching: false,
        strokeWidth: 0.75,
        strokeUniform: true,
        // strokeDashArray: [4, 4],
      });

      fabricRef.current.add(clipPathArea);
      fabricRef.current.bringToFront(clipPathArea);
      if (
        getState().selectedLayers.includes(
          `clipPath_${fabricSelectedPrintArea}`
        )
      ) {
        fabricRef.current.setActiveObject(clipPathArea);
        clipPathArea.setControlsVisibility({
          mrCustom: true,
          mbCustom: true,
          brCustom: true,
          mtr: true,
          unlock: false,
          lock: false,
          clone: false,
          deleteControl: false,
        });
      }
    } else if (findPrintAreaClipPath.clipPath.type === 'PATH') {
      const {
        left,
        top,
        shape,
        angle = 0,
        scaleX = 1,
        scaleY = 1,
      } = findPrintAreaClipPath.clipPath.data;

      const clipPath = new fabric.Path(shape, {
        top: top,
        left: left,
        scaleX: scaleX,
        scaleY: scaleY,
        angle: angle,
        id: `clipPath_${fabricSelectedPrintArea}`,
        type: 'PATH',
        absolutePositioned: true,
        originX: 'center',
        originY: 'center',
        centeredScaling: true,
      });

      fabricRef.current.clippingShape = clipPath;

      // ----- Add to workspace
      const clipPathArea = new fabric.Path(shape, {
        selectable: workspacesMode.mode === enumWorkspaceModes.EDIT_PRINT_AREAS,
        evented: workspacesMode.mode === enumWorkspaceModes.EDIT_PRINT_AREAS,
        top: top,
        left: left,
        scaleX: scaleX,
        scaleY: scaleY,
        angle: angle,
        type: 'PATH',
        id: `clipPath_${fabricSelectedPrintArea}`,
        // type: 'SKIP',
        cornerSize: cornerSize,
        centeredScaling: true,
        originX: 'center',
        originY: 'center',
        absolutePositioned: true,
        stroke: 'red',
        fill: '',
        objectCaching: false,
        strokeWidth: 0.75,
        strokeUniform: true,
        // strokeDashArray: [4, 4],
      });

      fabricRef.current.add(clipPathArea);
      fabricRef.current.bringToFront(clipPathArea);
      if (
        getState().selectedLayers.includes(
          `clipPath_${fabricSelectedPrintArea}`
        )
      ) {
        fabricRef.current.setActiveObject(clipPathArea);
        clipPathArea.setControlsVisibility({
          mrCustom: true,
          mbCustom: true,
          brCustom: true,
          mtr: true,
          unlock: false,
          lock: false,
          clone: false,
          deleteControl: false,
        });
      }
    }
  }

  fabricRef.current.getObjects().forEach(function (object) {
    if (
      object.type !== 'SKIP' &&
      object.id !== 'workspaceBorder' &&
      !object.id.startsWith('clipPath_')
    ) {
      if (fabricRef.current.clippingShape) {
        // updateClippingPath
        object.clipPath = fabricRef.current.clippingShape;
      } else {
        //remove clipingpath
        object.clipPath = undefined;
      }
    }
  });
};

export const ClipPathTextures = (refFabic, fabricId) => {
  const { textureSize, workspaceWidth, workspaceHeight } = resolutionProps(
    getState().qualityId,
    getState().fabricSettings
  );

  // --------- Clipping path

  if (refFabic.current) {
    const findModel = getState().currentClipPathList.find(
      (item) => item.idVariant === getState().selectedProductOptionsVariantId
    );

    const findPrintAreaClipPath = findModel.clipPath.find(
      (texture) => texture.idPrintArea === fabricId
    );

    // remove current clipath
    removeElementFromFabric(refFabic.current, `clipPath_${fabricId}`);

    if (findPrintAreaClipPath) {
      if (findPrintAreaClipPath.clipPath.type === 'RECT') {
        const {
          left,
          top,
          height,
          width,
          angle = 0,
          scaleX = 1,
          scaleY = 1,
        } = findPrintAreaClipPath.clipPath.data;

        // ----- Store for clipping objects
        const clipPath = new fabric.Rect({
          height: height,
          width: width,
          left: left * (textureSize / workspaceWidth),
          top: top * (textureSize / workspaceWidth),
          angle: angle,
          scaleX: scaleX * (textureSize / workspaceWidth),
          scaleY: scaleY * (textureSize / workspaceWidth),
          id: `clipPath_${fabricId}`,
          type: 'RECT',
          // type: 'SKIP',
          centeredScaling: true,
          originX: 'center',
          originY: 'center',
          absolutePositioned: true,
        });
        refFabic.current.clippingShape = clipPath;

        // ----- Add to workspace
        const clipPathArea = new fabric.Rect({
          selectable: false,
          evented: false,

          height: height,
          width: width,
          left: left * (textureSize / workspaceWidth),
          top: top * (textureSize / workspaceWidth),
          angle: angle,
          scaleX: scaleX * (textureSize / workspaceWidth),
          scaleY: scaleY * (textureSize / workspaceWidth),
          id: `clipPath_${fabricId}`,
          type: 'RECT',
          // type: 'SKIP',
          centeredScaling: true,
          originX: 'center',
          originY: 'center',
          absolutePositioned: true,
          stroke: 'red',
          strokeWidth: 0.75 * (textureSize / workspaceWidth),
          strokeUniform: true,
          fill: '',
          objectCaching: false,
          // strokeDashArray: [4, 4],
        });

        refFabic.current.add(clipPathArea);
        refFabic.current.bringToFront(clipPathArea);
        if (getState().selectedLayers.includes(`clipPath_${fabricId}`)) {
          refFabic.current.setActiveObject(clipPathArea);
          clipPathArea.setControlsVisibility({
            mtrTexture: true,
            mrCustomTexture: true,
            mbCustomTexture: true,
            brCustomTexture: true,
            deleteControlTexture: false,
            cloneTexture: false,
            lockTexture: false,
            unlockTexture: false,
          });
        }
      } else if (findPrintAreaClipPath.clipPath.type === 'CIRCLE') {
        const {
          left,
          top,
          radius,
          angle = 0,
          scaleX = 1,
          scaleY = 1,
        } = findPrintAreaClipPath.clipPath.data;

        // ----- Store for clipping objects
        const clipPath = new fabric.Circle({
          radius: radius,

          left: left * (textureSize / workspaceWidth),
          top: top * (textureSize / workspaceWidth),
          angle: angle,
          scaleX: scaleX * (textureSize / workspaceWidth),
          scaleY: scaleY * (textureSize / workspaceWidth),
          id: `clipPath_${fabricId}`,
          type: 'CIRCLE',
          // type: 'SKIP',
          centeredScaling: true,
          originX: 'center',
          originY: 'center',
          absolutePositioned: true,
        });

        refFabic.current.clippingShape = clipPath;

        // ----- Add to workspace
        const clipPathArea = new fabric.Circle({
          selectable: false,
          evented: false,

          radius: radius,

          left: left * (textureSize / workspaceWidth),
          top: top * (textureSize / workspaceWidth),
          angle: angle,
          scaleX: scaleX * (textureSize / workspaceWidth),
          scaleY: scaleY * (textureSize / workspaceWidth),
          id: `clipPath_${fabricId}`,
          type: 'CIRCLE',
          // type: 'SKIP',
          centeredScaling: true,
          originX: 'center',
          originY: 'center',
          // absolutePositioned: true,
          stroke: 'red',
          strokeWidth: 0.75 * (textureSize / workspaceWidth),
          strokeUniform: true,
          fill: '',
          objectCaching: false,

          // strokeDashArray: [4, 4],
        });

        refFabic.current.add(clipPathArea);
        refFabic.current.bringToFront(clipPathArea);
        if (getState().selectedLayers.includes(`clipPath_${fabricId}`)) {
          refFabic.current.setActiveObject(clipPathArea);
          clipPathArea.setControlsVisibility({
            mtrTexture: true,
            mrCustomTexture: true,
            mbCustomTexture: true,
            brCustomTexture: true,
            deleteControlTexture: false,
            cloneTexture: false,
            lockTexture: false,
            unlockTexture: false,
          });
        }
      } else if (findPrintAreaClipPath.clipPath.type === 'PATH') {
        const {
          left,
          top,
          shape,
          angle = 0,
          scaleX = 1,
          scaleY = 1,
        } = findPrintAreaClipPath.clipPath.data;
        const clipPath = new fabric.Path(shape, {
          left: left * (textureSize / workspaceWidth),
          top: top * (textureSize / workspaceWidth),
          angle: angle,
          scaleX: scaleX * (textureSize / workspaceWidth),
          scaleY: scaleY * (textureSize / workspaceWidth),

          id: `clipPath_${fabricId}`,
          type: 'PATH',
          absolutePositioned: true,
          originX: 'center',
          originY: 'center',
          centeredScaling: true,
        });

        // clipPath.rotate(30);

        refFabic.current.clippingShape = clipPath;

        // ----- Add to workspace
        const clipPathArea = new fabric.Path(shape, {
          selectable: false,
          evented: false,

          left: left * (textureSize / workspaceWidth),
          top: top * (textureSize / workspaceWidth),
          angle: angle,
          scaleX: scaleX * (textureSize / workspaceWidth),
          scaleY: scaleY * (textureSize / workspaceWidth),
          id: `clipPath_${fabricId}`,
          type: 'PATH',
          centeredScaling: true,
          originX: 'center',
          originY: 'center',
          absolutePositioned: true,
          stroke: 'red',
          strokeWidth: 0.75 * (textureSize / workspaceWidth),
          // strokeDashArray: [4, 4],
          strokeUniform: true,

          fill: '',
          objectCaching: false,
        });

        refFabic.current.add(clipPathArea);
        refFabic.current.bringToFront(clipPathArea);
        if (getState().selectedLayers.includes(`clipPath_${fabricId}`)) {
          refFabic.current.setActiveObject(clipPathArea);
          clipPathArea.setControlsVisibility({
            mtrTexture: true,
            mrCustomTexture: true,
            mbCustomTexture: true,
            brCustomTexture: true,
            deleteControlTexture: false,
            cloneTexture: false,
            lockTexture: false,
            unlockTexture: false,
          });
        }
      }
    } else {
      refFabic.current.clippingShape = null;
    }

    refFabic.current.getObjects().forEach(function (object) {
      if (
        object.type !== 'SKIP' &&
        object.id !== 'workspaceBorder' &&
        !object.id.startsWith('clipPath_')
      ) {
        if (refFabic.current.clippingShape) {
          // updateClippingPath
          object.clipPath = refFabic.current.clippingShape;
        } else {
          //remove clipingpath
          object.clipPath = undefined;
        }
      }
    });
  }
};

export const addPositioningLines = (fabricRef) => {
  const centeringLineHorizontal = new fabric.Line([0, 0, 20, 20], {
    type: 'SKIP',
    stroke: '#FF0000',
    strokeWidth: 1,
    visible: false,
    evented: false,
    selectable: false,
    id: 'positioningLineHorizontal',
  });
  fabricRef.current.add(centeringLineHorizontal);
  fabricRef.current.bringToFront(centeringLineHorizontal);

  const cnteringLineVertical = new fabric.Line([0, 0, 20, 20], {
    type: 'SKIP',
    stroke: '#FF0000',
    strokeWidth: 1,
    visible: false,
    evented: false,
    selectable: false,
    id: 'positioningLineVertical',
  });

  fabricRef.current.add(cnteringLineVertical);
  fabricRef.current.bringToFront(cnteringLineVertical);

  return {
    horizontalLine: centeringLineHorizontal,
    verticalLine: cnteringLineVertical,
  };
};
