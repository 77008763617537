import styled, { css } from 'styled-components';

export const CustomTooltipWrapper = styled.div`
  width: 100vw;
  max-width: 302px;
  display: grid;
  grid-template-rows: 30px auto;
  gap: 10px;

  background-color: ${({ theme }) => theme.color.toolTipBackground};

  box-shadow: 0px 2px 6px rgba(0, 0, 0, 0.4);

  border-radius: 3px;
  /* overflow: hidden; */
`;

export const WrapperTools = styled.div`
  background-color: ${({ theme }) => theme.color.toolTipBackgroundElement};
  padding: 10px;

  display: grid;
  gap: 10px;
  position: relative;
  border-radius: 0px 0px 3px 3px;
`;

export const SelectBox = styled.div`
  height: 40px;
  background-color: ${({ theme }) => theme.color.toolTipBackground};
  display: grid;
  grid-template-areas:
    'a c'
    'b c';
  grid-template-columns: 1fr 30px;
  grid-template-rows: 15px 1fr;
  padding: 5px 0px 5px 5px;
  justify-content: center;
  align-items: center;
  cursor: pointer;

  transition: ${({ theme }) => theme.parameter.transition};
  &:hover {
    background-color: ${({ theme }) => theme.color.selecboxHover};
  }

  svg {
    path {
      fill: ${({ theme }) => theme.color.toolTipDark} !important;
      transition: ${({ theme }) => theme.parameter.transition};
    }
  }
`;

export const SelectBoxTitle = styled.div`
  grid-area: a;
  font-size: 9px;
  color: ${({ theme }) => theme.color.toolTipDark};
`;

export const SelectBoxChoosen = styled.div`
  grid-area: b;
  font-size: 12px;
  color: ${({ theme }) => theme.color.toolTipDark};
`;

export const IconContainerSelectBox = styled.div`
  display: grid;
  transition: ${({ theme }) => theme.parameter.transition};

  align-items: center;
  justify-content: center;
`;

export const SelectOptionWrapper = styled.div`
  background-color: ${({ theme }) => theme.color.toolTipBackgroundElement};
  display: grid;
  grid-template-columns: 1fr;
  box-shadow: 0px 3px 6px 0px rgba(0, 0, 0, 0.5);
`;

export const SelectOptionRow = styled.div`
  position: relative;
  font-size: 14px;
  color: ${({ theme }) => theme.color.toolTipDark};
  padding: 8px 12px;
  cursor: pointer;

  text-align: center;

  transition: ${({ theme }) => theme.parameter.transition};

  &:hover {
    background: ${({ theme }) => theme.color.toolTipBackground};
  }

  ${({ $selected }) =>
    $selected &&
    css`
      cursor: auto;
      background: ${({ theme }) => theme.color.accent};
      color: ${({ theme }) => theme.color.main};

      &:hover {
        background: ${({ theme }) => theme.color.accent};
      }
    `};
`;

export const TextModificationWrapper = styled.div`
  display: flex;
  background: ${({ theme }) => theme.color.toolTipBackground};

  height: 35px;

  justify-content: center;

  /* background-color: ${({ theme }) => theme.color.backgroundLayoutOptions}; */
`;

export const IconContainerModification = styled.div`
  display: grid;
  cursor: pointer;
  transition: ${({ theme }) => theme.parameter.transition};

  &:hover {
    background-color: ${({ theme }) => theme.color.toolTipLight};
  }

  align-items: center;
  justify-content: center;
  min-width: 35px;

  ${({ $stroke }) =>
    $stroke &&
    css`
      svg {
        path {
          stroke: ${({ theme }) => theme.color.toolTipDark};
        }
      }
    `};

  ${({ $stroke }) =>
    !$stroke &&
    css`
      svg {
        path {
          fill: ${({ theme }) => theme.color.toolTipDark};
        }
      }
    `};

  ${({ $active }) =>
    $active &&
    css`
      background-color: ${({ theme }) => theme.color.dark};
      cursor: auto;
      &:hover {
        background-color: ${({ theme }) => theme.color.dark};
      }

      ${({ $stroke }) =>
        $stroke &&
        css`
          svg {
            path {
              stroke: ${({ theme }) => theme.color.main};
            }
          }
        `};

      ${({ $stroke }) =>
        !$stroke &&
        css`
          svg {
            path {
              fill: ${({ theme }) => theme.color.main};
            }
          }
        `};
    `};

  ${({ $activeToogleButton }) =>
    $activeToogleButton &&
    css`
      background-color: ${({ theme }) => theme.color.dark};
      cursor: pointer;
      &:hover {
        background-color: ${({ theme }) => theme.color.accent};
      }

      ${({ $stroke }) =>
        $stroke &&
        css`
          svg {
            path {
              stroke: ${({ theme }) => theme.color.main};
            }
          }
        `};

      ${({ $stroke }) =>
        !$stroke &&
        css`
          svg {
            path {
              fill: ${({ theme }) => theme.color.main};
            }
          }
        `};
    `};
`;
