import styled, { css } from 'styled-components';
import { animated } from 'react-spring';

export const Wrapper = styled(animated.div)`
  position: fixed;
  width: max-content;

  z-index: 100;
`;

export const Tip = styled.div`
  position: absolute;
  top: -9px;
  margin-right: 0px;
  margin-left: 0px;
  border-width: 5px;
  border-style: solid;
  border-color: transparent transparent
    ${({ theme }) => theme.color.toolTipBackgroundElement} transparent;
`;
