import { enumQuality, enumViewMode } from './enums';
import { blueGreenTheme, bluePinkTheme, magickBlueTheme } from 'constant/theme';

import View3DIcon from 'icon/view3D';
import View2DIcon from 'icon/view2D';
import View3D2DIcon from 'icon/view3D2D';

export const qualityOptions = [
  // {
  //   value: 0,
  //   label: { pl: 'Bardzo Wysoka jakość', en: 'Very High Quality' },
  //   quality: enumQuality.VERY_HIGH_QUALITY,
  //   textureSize: 8192,
  // },
  {
    value: 0,
    label: { pl: 'Wysoka jakość', en: 'High Quality' },
    quality: enumQuality.HIGH_QUALITY,
    textureSize: 2048,
  },
  {
    value: 1,
    label: { pl: 'Średnia jakość', en: 'Medium Quality' },
    quality: enumQuality.MEDIUM_QUALITY,
    textureSize: 2048,
  },
  {
    value: 2,
    label: { pl: 'Niska jakość', en: 'Low Quality' },
    quality: enumQuality.LOW_QUALITY,
    textureSize: 1024,
  },
  // {
  //   value: 4,
  //   label: { pl: 'Bardzo niska jakość', en: 'Very Low Quality' },
  //   quality: enumQuality.VERY_LOW_QUALITY,
  //   textureSize: 512,
  // },
];

export const themeOptions = [
  {
    value: 0,
    label: 'Magick blue',
    theme: magickBlueTheme,
    image: true,
  },
  { value: 1, label: 'Blue - Pink', theme: bluePinkTheme, image: false },
  {
    value: 2,
    label: 'Blue - Green',
    theme: blueGreenTheme,
    image: false,
  },
];

export const languageOptions = [
  { value: 0, label: 'Polski', language: 'pl' },
  {
    value: 1,
    label: 'English',
    language: 'en',
  },
];

export const currencyOptions = [
  { value: 0, label: 'PLN' },
  {
    value: 1,
    label: 'USD',
  },
];

export const viewModeOptions = [
  {
    value: 0,
    label: { pl: 'Obok siebie 3D i 2D', en: 'Side by side 3D and 2D' },
    enum: enumViewMode.THREE_AND_FABRIC,
    icon: <View3D2DIcon width={30} height={20} />,
  },

  {
    value: 1,
    label: { pl: 'Tylko 3D', en: 'Only 3D' },
    enum: enumViewMode.THREE,
    icon: <View3DIcon width={20} height={17} />,
  },
  {
    value: 2,
    label: { pl: 'Tylko 2D', en: 'Only 2D' },
    enum: enumViewMode.FABRIC,
    icon: <View2DIcon width={20} height={17} />,
  },
];

export const filtersList = [
  { filterName: 'Grayscale' },
  { filterName: 'Invert', defaultValue: false, type: 'BOOLEAN' },
  { filterName: 'Remove-color' },
  { filterName: 'Sepia' },
  { filterName: 'Brownie' },
  { filterName: 'Brightness', defaultValue: 0, type: 'RANGE' },
  { filterName: 'Contrast', defaultValue: 0, type: 'RANGE' },
  { filterName: 'Saturation', defaultValue: 0, type: 'RANGE' },
  { filterName: 'Vibrance', defaultValue: 0, type: 'RANGE' },
  { filterName: 'Noise', defaultValue: 0, type: 'RANGE' },
  { filterName: 'Vintage' },
  { filterName: 'Pixelate', defaultValue: 1, type: 'RANGE' },
  { filterName: 'Blur', defaultValue: 0, type: 'RANGE' },
  { filterName: 'Sharpen', defaultValue: false, type: 'BOOLEAN' },
  { filterName: 'Emboss' },
  { filterName: 'Technicolor' },
  { filterName: 'Polaroid' },
  { filterName: 'Blend-color' },
  { filterName: 'Gamma', defaultValue: [1, 1, 1], type: 'RANGE' },
  { filterName: 'Kodachrome' },
  { filterName: 'Blackwhite', defaultValue: false, type: 'BOOLEAN' },
  { filterName: 'Blend-image' },
  { filterName: 'Hue', defaultValue: 0, type: 'RANGE' },
  { filterName: 'Resize' },
];

///// ------ layers Export

export const ExportLayers = {
  layers: [
    {
      id: 0,
      label: {
        pl: 'Wszystkie z wybranego obszaru druku',
        en: 'All from print area',
      },
    },
    {
      id: 1,
      label: {
        pl: 'Zaznaczone z wybranego obszaru druku',
        en: 'Selected from print area',
      },
    },
  ],
  printArea: [
    { id: 0, label: { pl: 'Wszystkie obszary druku', en: 'All print areas' } },
  ],
  quality: [
    // { id: 0, label: { pl: 'Automatyczna Jakość', en: '0' } },
    { id: 1, label: { pl: '72 DPI', en: '72 DPI' } },
    { id: 2, label: { pl: '150 DPI', en: '150 DPI' } },
    { id: 3, label: { pl: '200 DPI', en: '200 DPI' } },
    { id: 4, label: { pl: '300 DPI', en: '300 DPI' } },
    // { id: 5, label: { pl: 'Upscale AI (Enchance) 300 DPI', en: '5' } },
  ],
  marginType: [
    { id: 0, label: { pl: 'Brak', en: 'No' } },
    { id: 1, label: { pl: 'Dodaj marginesy', en: 'Add margins' } },
  ],
  border: [
    { id: 0, label: { pl: 'Nie', en: 'No' } },
    { id: 1, label: { pl: 'Tak', en: 'Yes' } },
  ],
  pathBend: [
    { id: 0, label: { pl: 'Nie', en: 'No' } },
    { id: 1, label: { pl: 'Tak', en: 'Yes' } },
  ],
};

///// ------ Render settings

export const RenderSettingsList = {
  scenerySetup: [
    {
      id: 0,
      label: { pl: 'Transparent', en: 'Transparent' },
      value: { env: false, alpha: 0 },
    },
    {
      id: 1,
      label: { pl: 'Białe tło', en: 'White background' },
      value: { env: false, alpha: 0 },
    },
    {
      id: 2,
      label: { pl: 'Tło z oświetlenia', en: 'Background from light' },
      value: { env: true, alpha: 1 },
    },
    {
      id: 3,
      label: { pl: 'Tło + podstawa', en: 'Background + base' },
      value: { env: true, alpha: 1 },
    },
  ],
  lightSetup: [
    {
      id: 0,
      label: {
        pl: 'Ciepły - the sky is on fire',
        en: 'Warm - the sky is on fire',
      },
      value: {
        envUrl:
          'https://dl.polyhaven.org/file/ph-assets/HDRIs/hdr/2k/the_sky_is_on_fire_2k.hdr',
        imgPreview: '/assets/hdri/the_sky_is_on_fire.jpg',
      },
    },

    {
      id: 4,
      label: {
        pl: 'Ciepły - Cobblestone Street Night',
        en: 'Warm - Cobblestone Street Night',
      },
      value: {
        envUrl:
          'https://dl.polyhaven.org/file/ph-assets/HDRIs/hdr/2k/cobblestone_street_night_2k.hdr',
        imgPreview: '/assets/hdri/cobblestone_street_night.jpg',
      },
    },

    {
      id: 7,
      label: {
        pl: 'Ciepły - Autumn Forest 04',
        en: 'Warm - Autumn Forest 04',
      },
      value: {
        envUrl:
          'https://dl.polyhaven.org/file/ph-assets/HDRIs/hdr/2k/autumn_forest_04_2k.hdr',
        imgPreview: '/assets/hdri/autumn_forest_04.jpg',
      },
    },

    {
      id: 9,
      label: {
        pl: 'Ciepły - Veranda',
        en: 'Warm - Veranda',
      },
      value: {
        envUrl:
          'https://dl.polyhaven.org/file/ph-assets/HDRIs/hdr/2k/veranda_2k.hdr',
        imgPreview: '/assets/hdri/veranda.jpg',
      },
    },

    {
      id: 11,
      label: {
        pl: 'Ciepły - Table Mountain 2',
        en: 'Warm - Table Mountain 2',
      },
      value: {
        envUrl:
          'https://dl.polyhaven.org/file/ph-assets/HDRIs/hdr/2k/table_mountain_2_2k.hdr',
        imgPreview: '/assets/hdri/table_mountain_2.jpg',
      },
    },

    {
      id: 12,
      label: {
        pl: 'Ciepły - Circus Arena',
        en: 'Warm - Circus Arena',
      },
      value: {
        envUrl:
          'https://dl.polyhaven.org/file/ph-assets/HDRIs/hdr/2k/circus_arena_2k.hdr',
        imgPreview: '/assets/hdri/circus_arena.jpg',
      },
    },

    {
      id: 14,
      label: {
        pl: 'Ciepły - Christmas Photo Studio 04',
        en: 'Warm - Christmas Photo Studio 04',
      },
      value: {
        envUrl:
          'https://dl.polyhaven.org/file/ph-assets/HDRIs/hdr/2k/christmas_photo_studio_04_2k.hdr',
        imgPreview: '/assets/hdri/christmas_photo_studio_04.jpg',
      },
    },

    {
      id: 1,
      label: {
        pl: 'Naturalny - Brown Photostudio 02',
        en: 'Natural - Brown Photostudio 02',
      },
      value: {
        envUrl:
          'https://dl.polyhaven.org/file/ph-assets/HDRIs/hdr/2k/brown_photostudio_02_2k.hdr',
        imgPreview: '/assets/hdri/brown_photostudio_02.jpg',
      },
    },

    {
      id: 3,
      label: {
        pl: 'Naturalny - Symmetrical Garden 02',
        en: 'Natural - Symmetrical Garden 02',
      },
      value: {
        envUrl:
          'https://dl.polyhaven.org/file/ph-assets/HDRIs/hdr/2k/symmetrical_garden_02_2k.hdr',
        imgPreview: '/assets/hdri/symmetrical_garden_02.jpg',
      },
    },

    {
      id: 2,
      label: { pl: 'Zimny - Studio Small 01', en: 'Cold - Studio Small 01' },
      value: {
        envUrl:
          'https://dl.polyhaven.org/file/ph-assets/HDRIs/hdr/2k/studio_small_01_2k.hdr',
        imgPreview: '/assets/hdri/studio_small_02.jpg',
      },
    },

    {
      id: 5,
      label: {
        pl: 'Zimny - Kloofendal 48d Partly Cloudy',
        en: 'Cold - Kloofendal 48d Partly Cloudy',
      },
      value: {
        envUrl:
          'https://dl.polyhaven.org/file/ph-assets/HDRIs/hdr/2k/kloofendal_48d_partly_cloudy_puresky_2k.hdr',
        imgPreview: '/assets/hdri/kloofendal_48d_partly_cloudy_puresky.jpg',
      },
    },

    {
      id: 6,
      label: {
        pl: 'Zimny - Rainforest Trail',
        en: 'Cold - Rainforest Trail',
      },
      value: {
        envUrl:
          'https://dl.polyhaven.org/file/ph-assets/HDRIs/hdr/2k/rainforest_trail_2k.hdr',
        imgPreview: '/assets/hdri/rainforest_trail.jpg',
      },
    },

    {
      id: 8,
      label: {
        pl: 'Zimny - Snowy Field',
        en: 'Cold - Snowy Field',
      },
      value: {
        envUrl:
          'https://dl.polyhaven.org/file/ph-assets/HDRIs/hdr/2k/snowy_field_2k.hdr',
        imgPreview: '/assets/hdri/snowy_field.jpg',
      },
    },

    {
      id: 10,
      label: {
        pl: 'Zimny - Pool',
        en: 'Cold - Pool',
      },
      value: {
        envUrl:
          'https://dl.polyhaven.org/file/ph-assets/HDRIs/hdr/2k/pool_2k.hdr',
        imgPreview: '/assets/hdri/pool.jpg',
      },
    },

    {
      id: 13,
      label: {
        pl: 'Zimny - Provence Studio',
        en: 'Cold - Provence Studio',
      },
      value: {
        envUrl:
          'https://dl.polyhaven.org/file/ph-assets/HDRIs/hdr/2k/provence_studio_2k.hdr',
        imgPreview: '/assets/hdri/provence_studio.jpg',
      },
    },
  ],
  frameLimits: [
    { id: 0, label: { pl: 'Brak', en: 'No limits' } },
    { id: 1, label: { pl: '1000', en: '1000' } },
    { id: 2, label: { pl: '5000', en: '5000' } },
    { id: 3, label: { pl: '10000', en: '10000' } },
    { id: 4, label: { pl: '25000', en: '25000' } },
    { id: 5, label: { pl: '50000', en: '50000' } },
    { id: 6, label: { pl: '100000', en: '100000' } },
  ],
};

export const PrintAreaTypesList = [
  { id: 2, type: 'RECT', name: { pl: 'Prostokąt', en: 'Rectangle' } },
  { id: 3, type: 'CIRCLE', name: { pl: 'Koło', en: 'Circle' } },
  { id: 4, type: 'NULL', name: { pl: 'Brak', en: 'None' } },
];
