import React from 'react';

const View3D2DIcon = ({ width = 35, height = 25 }) => {
  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 35 25"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g clipPath="url(#clip0_861_2)">
        <path
          d="M20.5421 17H25.7209C26.0386 17 26.2379 16.8019 26.2379 16.4988C26.2379 16.2019 26.0386 16.0041 25.7209 16.0041H21.5704V15.973L24.0071 13.6227C25.4903 12.1937 25.9263 11.4514 25.9263 10.4988C25.9263 9.0826 24.6615 8 22.9727 8C21.6452 8 20.5111 8.71145 20.1307 9.79989C20.0563 10.0107 20.0268 10.1835 20.0268 10.3383C20.0268 10.6475 20.2119 10.8514 20.5172 10.8514C20.8103 10.8514 20.9348 10.7155 21.0283 10.4063C21.0781 10.2144 21.1467 10.0413 21.2463 9.88045C21.5766 9.33011 22.1937 8.97731 22.9727 8.97731C23.9822 8.97731 24.7861 9.68265 24.7861 10.5608C24.7861 11.2722 24.4933 11.7545 23.2593 12.9607L20.3429 15.8371C20.0812 16.0969 20 16.2516 20 16.4805C20 16.8022 20.2119 17 20.5421 17ZM28.0392 16.9875H30.594C33.3859 16.9875 35 15.336 35 12.5463C35 9.75682 33.3859 8.14224 30.594 8.14224H28.0389C27.6961 8.14224 27.4783 8.36503 27.4783 8.71118V16.4122C27.4783 16.7647 27.6963 16.9875 28.0389 16.9875M28.606 15.9732V9.14401H30.5129C32.6565 9.14401 33.8469 10.3813 33.8469 12.5524C33.8469 14.7356 32.6565 15.9727 30.5129 15.9727L28.606 15.9732Z"
          fill="white"
        />
        <path
          d="M3.12804 17C5.01241 17 6.36696 15.9107 6.36696 14.426C6.36696 13.2211 5.49268 12.4301 4.08857 12.3388V12.3144C5.25857 12.1196 5.985 11.3469 5.985 10.2638C5.985 8.94924 4.7783 8 3.10339 8C1.83509 8 0.806786 8.54768 0.369375 9.45429C0.25875 9.67929 0.203304 9.88629 0.203304 10.1237C0.203304 10.4096 0.400179 10.5984 0.683571 10.5984C0.966696 10.5984 1.08991 10.4951 1.18232 10.209C1.44696 9.41168 2.12438 8.95532 3.06643 8.95532C4.1625 8.95532 4.87071 9.52735 4.87071 10.4096C4.87071 11.3165 4.11938 11.9187 3.01098 11.9187H2.33384C2.04455 11.9187 1.85357 12.0953 1.85357 12.3753C1.85357 12.649 2.04429 12.8317 2.33384 12.8317H3.0233C4.39045 12.8317 5.20313 13.4034 5.20313 14.4199C5.20313 15.357 4.35938 16.0203 3.14063 16.0203C2.07509 16.0203 1.3117 15.5518 1.03446 14.7484C0.942054 14.4869 0.788036 14.3712 0.529554 14.3712C0.209464 14.3712 0 14.566 0 14.8824C0 15.0954 0.0554464 15.29 0.1725 15.5031C0.683571 16.4645 1.755 17 3.12804 17ZM8.12196 16.8478H10.6465C13.4054 16.8478 15 15.223 15 12.4788C15 9.73435 13.4052 8.14612 10.6465 8.14612H8.12196C7.78339 8.14612 7.56777 8.36503 7.56777 8.70571V16.2819C7.56777 16.6286 7.78339 16.8478 8.12196 16.8478ZM8.68232 15.8499V9.13162H10.5726C12.6849 9.13162 13.8608 10.3487 13.8608 12.4846C13.8608 14.6327 12.6849 15.8499 10.5726 15.8499H8.68232Z"
          fill="white"
        />
        <rect x="17" width="1" height="25" fill="white" />
      </g>
      <defs>
        <clipPath id="clip0_861_2">
          <rect width="35" height="25" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
};

export default View3D2DIcon;
