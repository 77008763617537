import * as THREE from 'three';

import { useStore, setState, getState } from 'store/store';

import { enumThreeDragTool, enumWorkspaceModes } from 'store/enums';

export function mouseRect(event, domElement) {
  const rect = domElement.getBoundingClientRect();
  const x = event.clientX - rect.left;
  const y = event.clientY - rect.top;
  const mouse = new THREE.Vector2(
    (x / rect.width) * 2 - 1,
    -(y / rect.height) * 2 + 1
  );

  return mouse;
}

export function getPointer(event, domElement) {
  const rect = domElement.getBoundingClientRect();

  return {
    x: ((event.clientX - rect.left) / rect.width) * 2 - 1,
    y: (-(event.clientY - rect.top) / rect.height) * 2 + 1,
    button: event.button,
  };
}

export function getFabricCoords(
  mouseEvent,
  fabricCanvas,
  camera,
  domElement,
  meshRef
) {
  const mouse = mouseRect(mouseEvent, domElement);

  const raycaster = new THREE.Raycaster();
  raycaster.setFromCamera(mouse, camera);
  const intersects = raycaster.intersectObjects([meshRef.current]);
  if (intersects.length > 0) {
    const uv = intersects[0].uv;
    return {
      x: uv.x * fabricCanvas.width,
      y: uv.y * fabricCanvas.height,
    };
  }
  return null;
}

export function isPointInCorner(object, point, resolution) {
  const cornerSize = (20 - 10) * resolution;
  // const cornerSize = object.cornerSize - 6;
  const coords = object.oCoords; // oCoords zawiera współrzędne narożników po transformacji

  let availableCornersArray = [
    'mtrTexture',
    'mrCustomTexture',
    'mbCustomTexture',
    'brCustomTexture',
    'deleteControlTexture',
    'cloneTexture',
    'lockTexture',
  ];

  if (object.islocked) {
    availableCornersArray = ['unlockTexture'];
  }

  if (getState().workspacesMode.mode === enumWorkspaceModes.EDIT_PRINT_AREAS) {
    availableCornersArray = [
      'mtrTexture',
      'mrCustomTexture',
      'mbCustomTexture',
      'brCustomTexture',
    ];
  }

  for (const corner in coords) {
    if (coords.hasOwnProperty(corner)) {
      // Sprawdzamy, czy punkt jest w obrębie kwadratu, który definiuje narożnik
      if (
        point.x >= coords[corner].x - cornerSize &&
        point.x <= coords[corner].x + cornerSize &&
        point.y >= coords[corner].y - cornerSize &&
        point.y <= coords[corner].y + cornerSize &&
        availableCornersArray.includes(corner)
      ) {
        return corner; // Zwraca narożnik, w którym znajduje się punkt
      }
    }
  }
  return false; // Punkt nie znajduje się w żadnym narożniku
}

// --------------------- rotation

export const findCoordsOnFabricWithOffset = (
  mtrX,
  mtrY,
  center,
  radiusReduction
) => {
  // Obliczenie wektora od mtr do centrum
  let vectorX = center.x - mtrX;
  let vectorY = center.y - mtrY;

  // Normalizacja wektora
  let magnitude = Math.sqrt(vectorX * vectorX + vectorY * vectorY);
  vectorX = vectorX / magnitude; // Znormalizowany wektor
  vectorY = vectorY / magnitude;

  // Obliczenie nowego promienia (pierwotny promień minus 10)
  let newRadius = magnitude - radiusReduction;

  // Obliczenie nowych współrzędnych
  let newX = center.x - vectorX * newRadius;
  let newY = center.y - vectorY * newRadius;

  return {
    x: newX,
    y: newY,
  };
};

export const calclLengthBetween2Points = (
  locationMTR1Point,
  locationMTR2Point,
  selectedObject,
  controlsRotationMtrOffset,
  radiusReduction
) => {
  const distancePoints = locationMTR1Point.distanceTo(locationMTR2Point);

  let halfHeight =
    selectedObject.getScaledHeight() / 2 + controlsRotationMtrOffset;

  return (halfHeight * distancePoints) / radiusReduction;
};

export const boundryBoxUV = (mesh) => {
  // Zakładając, że mesh posiada tylko jeden zestaw koordynatów UV

  let uvs = mesh.attributes.uv.array;

  let minX = Infinity,
    maxX = -Infinity;
  let minY = Infinity,
    maxY = -Infinity;

  for (let i = 0; i < uvs.length; i += 2) {
    let u = uvs[i];
    let v = uvs[i + 1];

    if (u < minX) minX = u;
    if (u > maxX) maxX = u;
    if (v < minY) minY = v;
    if (v > maxY) maxY = v;
  }

  return { x: { min: minX, max: maxX }, y: { min: minY, max: maxY } };
};

export const find3DpointFromUVCoordinates = (
  UV,
  object,
  getFaceQuaternion = false
) => {
  if (!(object.geometry instanceof THREE.BufferGeometry)) {
    console.error('Geometria obiektu nie jest typu BufferGeometry');
    return null;
  }

  const geometry = object.geometry;
  const positionAttr = geometry.attributes.position;
  const uvAttr = geometry.attributes.uv;
  const indexAttr = geometry.index;
  const morphAttributes = geometry.morphAttributes.position;
  const morphInfluences = object.morphTargetInfluences;
  let quaternion = null;

  for (let i = 0; i < indexAttr.count; i += 3) {
    const vertexIndices = [
      indexAttr.getX(i),
      indexAttr.getX(i + 1),
      indexAttr.getX(i + 2),
    ];
    const vertices = vertexIndices.map((index) => {
      let baseVertex = new THREE.Vector3().fromBufferAttribute(
        positionAttr,
        index
      );
      if (morphAttributes && morphInfluences) {
        for (let j = 0; j < morphAttributes.length; j++) {
          const morphAttr = morphAttributes[j];
          const influence = morphInfluences[j];
          if (influence !== 0) {
            const morphVertex = new THREE.Vector3().fromBufferAttribute(
              morphAttr,
              index
            );
            baseVertex.add(morphVertex.multiplyScalar(influence));
          }
        }
      }
      return baseVertex;
    });
    const uvs = vertexIndices.map((index) => {
      return new THREE.Vector2().fromBufferAttribute(uvAttr, index);
    });

    if (isPointInsideTriangle(UV, uvs[0], uvs[1], uvs[2])) {
      const baryCoords = getBarycentricCoordinates(UV, uvs[0], uvs[1], uvs[2]);
      let point3D = new THREE.Vector3();
      point3D.add(vertices[0].clone().multiplyScalar(baryCoords.x));
      point3D.add(vertices[1].clone().multiplyScalar(baryCoords.y));
      point3D.add(vertices[2].clone().multiplyScalar(baryCoords.z));

      if (getFaceQuaternion) {
        let faceNormal = new THREE.Vector3()
          .crossVectors(
            vertices[1].clone().sub(vertices[0]),
            vertices[2].clone().sub(vertices[0])
          )
          .normalize();
        quaternion = new THREE.Quaternion().setFromUnitVectors(
          new THREE.Vector3(0, 0, 1),
          faceNormal
        );
      }

      return getFaceQuaternion ? { point3D, quaternion } : point3D;
    }
  }

  console.error('Nie znaleziono punktu 3D dla podanych koordynatów UV');
  return null;
};

function isPointInsideTriangle(pt, v1, v2, v3) {
  // Obliczamy współrzędne barycentryczne dla punktu
  let barycentric = getBarycentricCoordinates(pt, v1, v2, v3);

  // Sprawdzamy, czy wszystkie współrzędne są dodatnie (punkt wewnątrz trójkąta)
  return barycentric.x >= 0 && barycentric.y >= 0 && barycentric.z >= 0;
}

function getBarycentricCoordinates(pt, v1, v2, v3) {
  let x = pt.x - v3.x;
  let y = pt.y - v3.y;
  let x1 = v1.x - v3.x;
  let x2 = v2.x - v3.x;
  let y1 = v1.y - v3.y;
  let y2 = v2.y - v3.y;
  let d = x1 * y2 - x2 * y1;
  let b1 = (x * y2 - x2 * y) / d;
  let b2 = (x1 * y - x * y1) / d;
  let b3 = 1 - b1 - b2;

  return { x: b1, y: b2, z: b3 };
}

export const normalizeAngle = (angle) => {
  const angleDeg = THREE.MathUtils.radToDeg(angle) % 360;
  return angleDeg >= 0 ? angleDeg : angleDeg + 360;
};

export function intersectObjectWithRay(object, raycaster, includeInvisible) {
  const allIntersections = raycaster.intersectObject(object, true);

  for (let i = 0; i < allIntersections.length; i++) {
    if (allIntersections[i].object.visible || includeInvisible) {
      return allIntersections[i];
    }
  }

  return false;
}

// ---------------------

export function calculateMm(px, calibrationSize) {
  const totalPx = 1024;
  const totalMm = calibrationSize;
  const mmPerPx = totalMm / totalPx;
  return px * mmPerPx;
}

export function mmToPx(mm, calibrationSize) {
  const totalPx = 1024;
  const totalMm = calibrationSize;
  const mmPerPx = totalPx / totalMm;
  return mm * mmPerPx;
}

export function CalculateRealWidth(element, calibrationSize, resolution = 1) {
  const elementWidth = element.width; // przykładowa szerokość elementu w pikselach
  const scale = element.scaleX; // przykładowa skala

  const scaledWidthPx = elementWidth * scale;

  const scaledWidthMm = calculateMm(
    scaledWidthPx * resolution,
    calibrationSize
  );
  return scaledWidthMm;
}

export function CalculateRealHeight(element, calibrationSize, resolution = 1) {
  const elementHeight = element.height; // przykładowa szerokość elementu w pikselach
  const scale = element.scaleY; // przykładowa skala

  const scaledHeightPx = elementHeight * scale;

  const scaledHeightMm = calculateMm(
    scaledHeightPx * resolution,
    calibrationSize
  );
  return scaledHeightMm;
}

export const findElementPrintArea = () => {
  // find print area for selected leayer
  let validPrintArea = getState().fabricSelectedPrintArea;

  getState().refTextureFabricArray.forEach((textureFabric) => {
    if (textureFabric.fabricRef.current) {
      textureFabric.fabricRef.current.getObjects().forEach(function (object) {
        if (!getState().selectedLayers.includes(object.id)) return;
        validPrintArea = textureFabric.id;
      });
    }
  });

  return validPrintArea;
};

export const CursorDescription = (controls) => {
  getState().refCursorDescription.current.style.display = 'block';

  if (controls === enumThreeDragTool.POSITION) {
    getState().refCursorDescription.current.innerText =
      'X: ' +
      parseFloat(getState().worksapcePositionX).toFixed(2) +
      ' mm\nY: ' +
      parseFloat(getState().worksapcePositionY).toFixed(2) +
      ' mm';
  } else if (controls === enumThreeDragTool.ROTATE) {
    getState().refCursorDescription.current.innerText =
      parseFloat(getState().worksapceToolAngle).toFixed(2) + '°';
  } else if (
    controls === enumThreeDragTool.SCALE_HEIGHT ||
    controls === enumThreeDragTool.SCALE_WIDTH ||
    controls === enumThreeDragTool.SCALE_WIDTH_HEIGHT
  ) {
    getState().refCursorDescription.current.innerText =
      'W: ' +
      parseFloat(getState().worksapceSizeX).toFixed(2) +
      ' mm\nH: ' +
      parseFloat(getState().worksapceSizeY).toFixed(2) +
      ' mm';
  }
};

export const findClipPathReference = (printAreaId) => {
  // --------------------------------------- object
  const findModel = getState().currentClipPathList.find(
    (item) => item.idVariant === getState().selectedProductOptionsVariantId
  );

  if (findModel) {
    const findClipPath = findModel.clipPath.find(
      (item) => item.idPrintArea == printAreaId
    );

    return findClipPath;
  }

  return null;
};
