export const fontList = [
  {
    id: 0,
    name: 'Pacifico',
    iconURL: '/assets/fonts/icons/Pacyfico.svg',
  },

  {
    id: 1,
    name: 'VT323',
    iconURL: '/assets/fonts/icons/VT323.svg',
  },
  {
    id: 2,
    name: 'Quicksand',
    iconURL: '/assets/fonts/icons/Quicksand.svg',
  },
  {
    id: 3,
    name: 'Inconsolata',
    iconURL: '/assets/fonts/icons/Inconsolata.svg',
  },

  {
    id: 4,
    name: 'Jim Nightshade',
    iconURL: '/assets/fonts/icons/Jim Nightshade.svg',
  },

  {
    id: 5,
    name: 'Inspiration',
    iconURL: '/assets/fonts/icons/Inspiration.svg',
  },

  {
    id: 6,
    name: 'Ingrid Darling',
    iconURL: '/assets/fonts/icons/Ingrid Darling.svg',
  },

  {
    id: 7,
    name: 'Rock Salt',
    iconURL: '/assets/fonts/icons/Rock Salt.svg',
  },

  {
    id: 8,
    name: 'Babylonica',
    iconURL: '/assets/fonts/icons/Babylonica.svg',
  },

  {
    id: 9,
    name: 'Bruno Ace SC',
    iconURL: '/assets/fonts/icons/Bruno Ace SC.svg',
  },

  {
    id: 10,
    name: 'Cormorant SC',
    iconURL: '/assets/fonts/icons/Cormorant SC.svg',
  },

  {
    id: 11,
    name: 'Finger Paint',
    iconURL: '/assets/fonts/icons/Finger Paint.svg',
  },

  {
    id: 12,
    name: 'Kalam',
    iconURL: '/assets/fonts/icons/Kalam.svg',
  },

  {
    id: 13,
    name: 'MedievalSharp',
    iconURL: '/assets/fonts/icons/MedievalSharp.svg',
  },

  {
    id: 14,
    name: 'Merienda',
    iconURL: '/assets/fonts/icons/Merienda.svg',
  },

  {
    id: 15,
    name: 'MonteCarlo',
    iconURL: '/assets/fonts/icons/MonteCarlo.svg',
  },

  {
    id: 16,
    name: 'Montez',
    iconURL: '/assets/fonts/icons/Montez.svg',
  },
  {
    id: 17,
    name: 'My Soul',
    iconURL: '/assets/fonts/icons/My Soul.svg',
  },
  {
    id: 18,
    name: 'Orbitron',
    iconURL: '/assets/fonts/icons/Orbitron.svg',
  },
  {
    id: 19,
    name: 'Rubik',
    iconURL: '/assets/fonts/icons/Rubik.svg',
  },

  {
    id: 20,
    name: 'Inter',
    iconURL: '/assets/fonts/icons/Inter.svg',
  },
  {
    id: 21,
    name: 'Yuji Boku',
    iconURL: '/assets/fonts/icons/Yuji Boku.svg',
  },
  {
    id: 22,
    name: 'Poppins',
    iconURL: '/assets/fonts/icons/Poppins.svg',
  },
  {
    id: 23,
    name: 'Rampart One',
    iconURL: '/assets/fonts/icons/Rampart One.svg',
  },
  {
    id: 24,
    name: 'Grechen Fuemen',
    iconURL: '/assets/fonts/icons/Grechen Fuemen.svg',
  },
  {
    id: 25,
    name: 'Modak',
    iconURL: '/assets/fonts/icons/Modak.svg',
  },
];
