import { fabric } from 'fabric';
import { enumLayerType, enumWorkspaceModes } from 'store/enums';

import { useStore, setState, getState } from 'store/store';
import { resolutionProps } from 'layout/configurator/rightSide/workspace/components/fabric/utils/utils';

// Funkcja pomocnicza do przeliczania mm na cale
export const mmToInch = (mm) => mm / 25.4;

// Funkcja do obliczania bounding box po rotacji
export const calculateBoundingBox = (width, height, originalRect) => {
  const angleDegrees = originalRect.data ? originalRect.data.angle : 0;

  const angleRadians = angleDegrees * (Math.PI / 180);
  const sin = Math.abs(Math.sin(angleRadians));
  const cos = Math.abs(Math.cos(angleRadians));
  const boundingWidth = width * cos + height * sin;
  const boundingHeight = width * sin + height * cos;
  return { boundingWidth, boundingHeight };
};

export const CalcRealSizeForRectangle = (
  originalRect,
  calibrationSize_mm,
  dpi
) => {
  const { scaledWidth_mm, scaledHeight_mm } = calcRealSizeOfPrintArea(
    originalRect,
    calibrationSize_mm
  );

  // Krok 3: Uwzględnienie rotacji - obliczenie bounding box po rotacji
  const rotatedDimensions = calculateBoundingBox(
    scaledWidth_mm,
    scaledHeight_mm,
    originalRect
  );

  // Krok 4: Przeliczenie mm na cale
  const width_inch = mmToInch(rotatedDimensions.boundingWidth);
  const height_inch = mmToInch(rotatedDimensions.boundingHeight);

  // Krok 5: Przeliczenie cali na piksele na podstawie DPI
  const width_print_px = Math.ceil(width_inch * dpi);
  const height_print_px = Math.ceil(height_inch * dpi);
  return { width_print_px, height_print_px };
};

const calcRealSizeOfPrintArea = (originalRect, calibrationSize_mm) => {
  const calibrationSize_px = 1024;

  // Krok 1: Obliczenie przeskalowanych wymiarów w pikselach
  let scaledWidth_px = 0;
  let scaledHeight_px = 0;

  if (originalRect.type === 'CIRCLE') {
    const pathWidth = originalRect.data.radius * 2;
    const pathHeight = originalRect.data.radius * 2;
    scaledWidth_px = pathWidth * originalRect.data.scaleX;
    scaledHeight_px = pathHeight * originalRect.data.scaleY;
  } else if (originalRect.type === 'RECT') {
    const pathWidth = originalRect.data.width;
    const pathHeight = originalRect.data.height;
    scaledWidth_px = pathWidth * originalRect.data.scaleX;
    scaledHeight_px = pathHeight * originalRect.data.scaleY;
  } else if (originalRect.type === 'PATH') {
    const shapePath = new fabric.Path(originalRect.data.shape, {});
    const pathWidth = shapePath.width;
    const pathHeight = shapePath.height;
    scaledWidth_px = pathWidth * originalRect.data.scaleX;
    scaledHeight_px = pathHeight * originalRect.data.scaleY;
  } else {
    scaledWidth_px = 1024;
    scaledHeight_px = 1024;
  }

  // Krok 2: Przeliczenie pikseli na milimetry
  const mmPerPx = calibrationSize_mm / calibrationSize_px;

  const scaledWidth_mm = scaledWidth_px * mmPerPx;
  const scaledHeight_mm = scaledHeight_px * mmPerPx;

  return { scaledWidth_mm, scaledHeight_mm };
};

export function getBoundingBoxDimensions(width, height, angleDegrees) {
  const angleRadians = (angleDegrees * Math.PI) / 180; // Konwersja stopni na radiany
  const cos = Math.abs(Math.cos(angleRadians));
  const sin = Math.abs(Math.sin(angleRadians));
  const bboxWidth = width * cos + height * sin;
  const bboxHeight = width * sin + height * cos;
  return { width: bboxWidth, height: bboxHeight };
}

//////////////////////////////////////------------------------------
//////////////////////////////////////------------------------------
//////////////////////////////////////------------------------------

export function mmToPixels(mm, dpi = 72) {
  const mmToInches = 1 / 25.4;
  const inchesToPixels = dpi;
  return mm * mmToInches * inchesToPixels;
}

export function calculatePixelsToMilimiters(value, calibrationSize) {
  return (value * calibrationSize) / 1024;
}

export const calculateValueWithDPI = (
  currentSize,
  originalWidth,
  originalHeight,
  newWidth,
  newHeight
) => {
  const widthRatio = newWidth / originalWidth;
  const heightRatio = newHeight / originalHeight;
  const scaleRatio = (widthRatio + heightRatio) / 2;
  return currentSize * scaleRatio;
};

export function cloneAndAddObjects(
  sourceCanvas,
  staticCanvas,
  originalRect,
  calibrationSize_mm,
  dpi,
  border,
  margin
) {
  const { textureSize, workspaceWidth } = resolutionProps(
    getState().qualityId,
    getState().fabricSettings
  );
  const scaleMultiplier = textureSize / workspaceWidth;

  let loadPromises = [];

  const { scaledWidth_mm, scaledHeight_mm } = calcRealSizeOfPrintArea(
    originalRect,
    calibrationSize_mm
  );

  // Krok 3: Uwzględnienie rotacji - obliczenie bounding box po rotacji
  const rotatedDimensions = calculateBoundingBox(
    scaledWidth_mm,
    scaledHeight_mm,
    originalRect
  );

  // Krok 5: Przeliczenie cali na piksele na podstawie DPI
  const roated_width_print_px = Math.ceil(
    mmToInch(rotatedDimensions.boundingWidth) * dpi
  );
  const roated_height_print_px = Math.ceil(
    mmToInch(rotatedDimensions.boundingHeight) * dpi
  );

  // Krok 5: Przeliczenie cali na piksele na podstawie DPI
  const not_roated_width_print_px = Math.ceil(mmToInch(scaledWidth_mm) * dpi);
  const not_roated_height_print_px = Math.ceil(mmToInch(scaledHeight_mm) * dpi);

  const difference_width =
    (not_roated_width_print_px - roated_width_print_px) / 2;
  const difference_Height =
    (not_roated_height_print_px - roated_height_print_px) / 2;
  ////

  let originalWidth = 0;
  let originalHeight = 0;

  if (originalRect.type === 'CIRCLE') {
    const pathWidth = originalRect.data.radius * 2;
    const pathHeight = originalRect.data.radius * 2;

    originalWidth = pathWidth * originalRect.data.scaleX * scaleMultiplier;
    originalHeight = pathHeight * originalRect.data.scaleY * scaleMultiplier;
  } else if (originalRect.type === 'RECT') {
    const pathWidth = originalRect.data.width;
    const pathHeight = originalRect.data.height;
    originalWidth = pathWidth * originalRect.data.scaleX * scaleMultiplier;
    originalHeight = pathHeight * originalRect.data.scaleY * scaleMultiplier;
  } else if (originalRect.type === 'PATH') {
    const shapePath = new fabric.Path(originalRect.data.shape, {});

    const pathWidth = shapePath.width;
    const pathHeight = shapePath.height;

    originalWidth = pathWidth * originalRect.data.scaleX * scaleMultiplier;
    originalHeight = pathHeight * originalRect.data.scaleY * scaleMultiplier;
  } else {
    originalWidth = 1024 * scaleMultiplier;
    originalHeight = 1024 * scaleMultiplier;
  }

  ///-----------------------------
  /// Clip path

  let ClippingPathLeftTopDistance = {
    left: 0,
    top: 0,
  };

  let clipPath = null;

  if (sourceCanvas.clippingShape) {
    ClippingPathLeftTopDistance = {
      left: originalRect.data.left * scaleMultiplier - originalWidth / 2,
      top: originalRect.data.top * scaleMultiplier - originalHeight / 2,
    };

    if (originalRect.type === 'RECT') {
      clipPath = new fabric.Rect({
        height: sourceCanvas.clippingShape.height,
        width: sourceCanvas.clippingShape.width,
        left:
          calculateValueWithDPI(
            sourceCanvas.clippingShape.left - ClippingPathLeftTopDistance.left,
            originalWidth,
            originalHeight,
            not_roated_width_print_px,
            not_roated_height_print_px
          ) -
          difference_width +
          margin.rotatedWidth / 2 +
          border.safeSpace,
        top:
          calculateValueWithDPI(
            sourceCanvas.clippingShape.top - ClippingPathLeftTopDistance.top,
            originalWidth,
            originalHeight,
            not_roated_width_print_px,
            not_roated_height_print_px
          ) -
          difference_Height +
          margin.rotatedHeight / 2 +
          border.safeSpace,
        angle: sourceCanvas.clippingShape.angle,
        scaleX: calculateValueWithDPI(
          sourceCanvas.clippingShape.scaleX,
          originalWidth,
          originalHeight,
          not_roated_width_print_px,
          not_roated_height_print_px
        ),
        scaleY: calculateValueWithDPI(
          sourceCanvas.clippingShape.scaleY,
          originalWidth,
          originalHeight,
          not_roated_width_print_px,
          not_roated_height_print_px
        ),
        id: `clipPath_`,
        type: 'RECT',
        centeredScaling: true,
        originX: 'center',
        originY: 'center',
        absolutePositioned: true,
      });
    } else if (originalRect.type === 'CIRCLE') {
      clipPath = new fabric.Circle({
        radius: sourceCanvas.clippingShape.radius,
        left:
          calculateValueWithDPI(
            sourceCanvas.clippingShape.left - ClippingPathLeftTopDistance.left,
            originalWidth,
            originalHeight,
            not_roated_width_print_px,
            not_roated_height_print_px
          ) -
          difference_width +
          margin.rotatedWidth / 2 +
          border.safeSpace,
        top:
          calculateValueWithDPI(
            sourceCanvas.clippingShape.top - ClippingPathLeftTopDistance.top,
            originalWidth,
            originalHeight,
            not_roated_width_print_px,
            not_roated_height_print_px
          ) -
          difference_Height +
          margin.rotatedHeight / 2 +
          border.safeSpace,
        angle: sourceCanvas.clippingShape.angle,
        scaleX: calculateValueWithDPI(
          sourceCanvas.clippingShape.scaleX,
          originalWidth,
          originalHeight,
          not_roated_width_print_px,
          not_roated_height_print_px
        ),
        scaleY: calculateValueWithDPI(
          sourceCanvas.clippingShape.scaleY,
          originalWidth,
          originalHeight,
          not_roated_width_print_px,
          not_roated_height_print_px
        ),
        id: `clipPath_`,
        type: 'RECT',
        centeredScaling: true,
        originX: 'center',
        originY: 'center',
        absolutePositioned: true,
      });
    } else if (originalRect.type === 'PATH') {
      clipPath = new fabric.Path(originalRect.data.shape, {
        left:
          calculateValueWithDPI(
            sourceCanvas.clippingShape.left - ClippingPathLeftTopDistance.left,
            originalWidth,
            originalHeight,
            not_roated_width_print_px,
            not_roated_height_print_px
          ) -
          difference_width +
          margin.rotatedWidth / 2 +
          border.safeSpace,
        top:
          calculateValueWithDPI(
            sourceCanvas.clippingShape.top - ClippingPathLeftTopDistance.top,
            originalWidth,
            originalHeight,
            not_roated_width_print_px,
            not_roated_height_print_px
          ) -
          difference_Height +
          margin.rotatedHeight / 2 +
          border.safeSpace,
        angle: sourceCanvas.clippingShape.angle,
        scaleX: calculateValueWithDPI(
          sourceCanvas.clippingShape.scaleX,
          originalWidth,
          originalHeight,
          not_roated_width_print_px,
          not_roated_height_print_px
        ),
        scaleY: calculateValueWithDPI(
          sourceCanvas.clippingShape.scaleY,
          originalWidth,
          originalHeight,
          not_roated_width_print_px,
          not_roated_height_print_px
        ),
        id: `clipPath_`,
        type: 'RECT',
        centeredScaling: true,
        originX: 'center',
        originY: 'center',
        absolutePositioned: true,
      });
    }
  }

  sourceCanvas.getObjects().forEach(function (object) {
    let promise = new Promise((resolve, reject) => {
      if (object.type === 'SKIP' || object.id.startsWith('clipPath_')) {
        resolve();
        return;
      }

      if (object.clipPath) {
        object.clipPath = undefined;
      }

      object.clone(function (cloned) {
        if (!cloned) {
          reject(new Error('Image load failed'));
          return;
        }

        // Przeskalowanie i pozycjonowanie klonowanego obiektu
        cloned.set({
          left:
            calculateValueWithDPI(
              cloned.left - ClippingPathLeftTopDistance.left,
              originalWidth,
              originalHeight,
              not_roated_width_print_px,
              not_roated_height_print_px
            ) -
            difference_width +
            margin.rotatedWidth / 2 +
            border.safeSpace,
          top:
            calculateValueWithDPI(
              cloned.top - ClippingPathLeftTopDistance.top,
              originalWidth,
              originalHeight,
              not_roated_width_print_px,
              not_roated_height_print_px
            ) -
            difference_Height +
            margin.rotatedHeight / 2 +
            border.safeSpace,
          scaleX: calculateValueWithDPI(
            cloned.scaleX,
            originalWidth,
            originalHeight,
            not_roated_width_print_px,
            not_roated_height_print_px
          ),
          scaleY: calculateValueWithDPI(
            cloned.scaleY,
            originalWidth,
            originalHeight,
            not_roated_width_print_px,
            not_roated_height_print_px
          ),
          originX: 'center',
          originY: 'center',
          selectable: false, // Wyłączenie możliwości zaznaczania
          evented: false, // Wyłączenie reakcji na zdarzenia
        });

        if (clipPath) {
          cloned.clipPath = clipPath;
        }

        // Dodanie klonowanego obiektu do staticCanvas

        if (cloned) {
          staticCanvas.add(cloned);
          resolve();
        } else {
          reject();
        }
      });

      if (sourceCanvas.clippingShape) {
        object.clipPath = sourceCanvas.clippingShape;
      }
    });

    loadPromises.push(promise);
  });

  BorderRealSizeForRectangle(
    border,
    originalRect,
    roated_width_print_px,
    roated_height_print_px,
    sourceCanvas,
    staticCanvas,
    originalWidth,
    originalHeight,
    not_roated_width_print_px,
    not_roated_height_print_px,
    margin
  );

  return Promise.all(loadPromises);
}

export const BorderRealSizeForRectangle = (
  border,
  originalRect,
  roated_width_print_px,
  roated_height_print_px,
  sourceCanvas,
  staticCanvas,
  originalWidth,
  originalHeight,
  not_roated_width_print_px,
  not_roated_height_print_px,
  margin
) => {
  if (border.show) {
    if (originalRect.type === 'RECT') {
      const borderClipPath = new fabric.Rect({
        left:
          roated_width_print_px / 2 +
          margin.rotatedWidth / 2 +
          border.safeSpace, // Środek poziomy canvasu
        top:
          roated_height_print_px / 2 +
          margin.rotatedHeight / 2 +
          border.safeSpace, // Środek pionowy canvasu
        originX: 'center', // Ustawienie punktu odniesienia na środek
        originY: 'center', // Ustawienie punktu odniesienia na środek
        width: not_roated_width_print_px + margin.distance * 2,
        height: not_roated_height_print_px + margin.distance * 2,

        // scaleX: calculateValueWithDPI(
        //   sourceCanvas.clippingShape.scaleX,
        //   originalWidth,
        //   originalHeight,
        //   not_roated_width_print_px + margin.distance * 2,
        //   not_roated_height_print_px + margin.distance * 2
        // ),
        // scaleY: calculateValueWithDPI(
        //   sourceCanvas.clippingShape.scaleY,
        //   originalWidth,
        //   originalHeight,
        //   not_roated_width_print_px + margin.distance * 2,
        //   not_roated_height_print_px + margin.distance * 2
        // ),

        angle: originalRect.data.angle, // Obrót prostokąta
        id: 'printBorder',
        fill: 'transparent',
        stroke: 'red',
        strokeWidth: 1,
        strokeUniform: true,
        selectable: false,
        evented: false,
      });

      staticCanvas.add(borderClipPath);
    } else if (originalRect.type === 'CIRCLE') {
      const borderClipPath = new fabric.Circle({
        radius: sourceCanvas.clippingShape.radius,
        left:
          roated_width_print_px / 2 +
          margin.rotatedWidth / 2 +
          border.safeSpace, // Środek poziomy canvasu
        top:
          roated_height_print_px / 2 +
          margin.rotatedHeight / 2 +
          border.safeSpace, // Środek pionowy canvasu

        centeredScaling: true,
        originX: 'center',
        originY: 'center',

        scaleX: calculateValueWithDPI(
          sourceCanvas.clippingShape.scaleX,
          originalWidth,
          originalHeight,
          not_roated_width_print_px + margin.distance * 2,
          not_roated_height_print_px + margin.distance * 2
        ),
        scaleY: calculateValueWithDPI(
          sourceCanvas.clippingShape.scaleY,
          originalWidth,
          originalHeight,
          not_roated_width_print_px + margin.distance * 2,
          not_roated_height_print_px + margin.distance * 2
        ),
        id: 'printBorder',
        angle: sourceCanvas.clippingShape.angle,
        fill: 'transparent',
        stroke: 'red',
        strokeWidth: 1,
        strokeUniform: true,
        selectable: false,
        evented: false,
      });
      staticCanvas.add(borderClipPath);
    } else if (originalRect.type === 'PATH') {
      const borderClipPath = new fabric.Path(originalRect.data.shape, {
        left:
          roated_width_print_px / 2 +
          margin.rotatedWidth / 2 +
          border.safeSpace, // Środek poziomy canvasu
        top:
          roated_height_print_px / 2 +
          margin.rotatedHeight / 2 +
          border.safeSpace, // Środek pionowy canvasu

        centeredScaling: true,
        originX: 'center',
        originY: 'center',

        scaleX: calculateValueWithDPI(
          sourceCanvas.clippingShape.scaleX,
          originalWidth,
          originalHeight,
          not_roated_width_print_px + margin.distance * 2,
          not_roated_height_print_px + margin.distance * 2
        ),
        scaleY: calculateValueWithDPI(
          sourceCanvas.clippingShape.scaleY,
          originalWidth,
          originalHeight,
          not_roated_width_print_px + margin.distance * 2,
          not_roated_height_print_px + margin.distance * 2
        ),

        id: 'printBorder',
        angle: sourceCanvas.clippingShape.angle,
        fill: 'transparent',
        stroke: 'red',
        strokeWidth: 1,
        strokeUniform: true,
        selectable: false,
        evented: false,
      });
      staticCanvas.add(borderClipPath);
    } else {
      const borderClipPath = new fabric.Rect({
        left: roated_width_print_px / 2 + margin.distance + border.safeSpace, // Środek poziomy canvasu
        top: roated_height_print_px / 2 + margin.distance + border.safeSpace, // Środek pionowy canvasu
        originX: 'center', // Ustawienie punktu odniesienia na środek
        originY: 'center', // Ustawienie punktu odniesienia na środek
        width: roated_width_print_px + margin.distance * 2,
        height: roated_height_print_px + margin.distance * 2,

        scaleX: 1,
        scaleY: 1,

        angle: 0, // Obrót prostokąta
        id: 'printBorder',
        fill: 'transparent',
        stroke: 'red',
        strokeWidth: 1,
        strokeUniform: true,
        selectable: false,
        evented: false,
      });

      staticCanvas.add(borderClipPath);
    }
  }
};
