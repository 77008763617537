import React from 'react';

const View3DIcon = ({ width = 24, height = 25 }) => {
  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 24 25"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M5.00486 20C8.01986 20 10.1871 18.1846 10.1871 15.71C10.1871 13.7018 8.78829 12.3835 6.54171 12.2313V12.1907C8.41371 11.866 9.576 10.5782 9.576 8.77294C9.576 6.58206 7.64529 5 4.96543 5C2.93614 5 1.29086 5.91279 0.591 7.42382C0.414 7.79882 0.325286 8.14382 0.325286 8.53956C0.325286 9.01603 0.640286 9.33059 1.09371 9.33059C1.54671 9.33059 1.74386 9.15853 1.89171 8.68162C2.31514 7.35279 3.399 6.59221 4.90629 6.59221C6.66 6.59221 7.79314 7.54559 7.79314 9.01603C7.79314 10.5275 6.591 11.5312 4.81757 11.5312H3.73414C3.27129 11.5312 2.96571 11.8254 2.96571 12.2922C2.96571 12.7484 3.27086 13.0528 3.73414 13.0528H4.83729C7.02471 13.0528 8.325 14.0057 8.325 15.6999C8.325 17.2616 6.975 18.3672 5.025 18.3672C3.32014 18.3672 2.09871 17.5863 1.65514 16.2474C1.50729 15.8115 1.26086 15.6187 0.847286 15.6187C0.335143 15.6187 0 15.9434 0 16.4706C0 16.8257 0.0887143 17.15 0.276 17.5051C1.09371 19.1075 2.808 20 5.00486 20ZM12.9951 19.7463H17.0344C21.4487 19.7463 24 17.0384 24 12.4647C24 7.89059 21.4483 5.24353 17.0344 5.24353H12.9951C12.4534 5.24353 12.1084 5.60838 12.1084 6.17618V18.8031C12.1084 19.381 12.4534 19.7463 12.9951 19.7463ZM13.8917 18.0831V6.88603H16.9161C20.2959 6.88603 22.1773 8.91456 22.1773 12.4744C22.1773 16.0546 20.2959 18.0831 16.9161 18.0831H13.8917Z"
        fill="white"
      />
    </svg>
  );
};

export default View3DIcon;
