import { useStore, setState } from 'store/store';

import { selectObjectsByLayers } from 'layout/configurator/rightSide/workspace/components/fabric/utils/utils';

// ------------ Selecting Logick

export const deselectLogic = (set) => {
  setState((state) => {
    if (state.refFabric.current) {
      selectObjectsByLayers(state.refFabric.current, []);
      state.refFabric.current.requestRenderAll();
    }

    state.refTextureFabricArray.forEach((element) => {
      selectObjectsByLayers(element.fabricRef.current, [], true);
      element.fabricRef.current.requestRenderAll();
    });

    return {
      selectedLayers: [],

      worksapceToolToolTipTextEdit: {
        open: false,
        newObject: false,
      },
      worksapceToolToolTipImageEdit: { open: false },
      worksapceToolToolTipTranslation: {
        open: false,
        objectType: null,
      },
      worksapceToolToolTipTextFillColor: { open: false },
      worksapceToolToolTipTextBorderColor: { open: false },
      worksapceToolToolTipTextFont: { open: false },
      worksapceToolToolTipImageFilters: { open: false },
      worksapceToolToolTipImageFillColor: { open: false },
      worksapceToolToolTipTextShadow: { open: false },
      worksapceToolToolTipClipPathShape: { open: false },
    };
  });
};

export const deselectHandler = (event, refSortable, set) => {
  if (
    event.target === refSortable.current ||
    event.target.dataset.shouldDeselect
  ) {
    deselectLogic(set);
  }
};

export const selectItem = (
  e,
  id,
  layers,
  selectedLayers,
  isCtrlPressed,
  isShiftPressed
) => {
  let helperSelectedLayers = [...selectedLayers];

  if (isCtrlPressed) {
    if (helperSelectedLayers.includes(id)) {
      helperSelectedLayers = helperSelectedLayers.filter((item) => item !== id);
    } else {
      helperSelectedLayers.push(id);
    }
  } else if (isShiftPressed) {
    if (helperSelectedLayers.length === 0) {
      helperSelectedLayers.push(id);
    } else {
      const indexesList = [];

      Object.keys(layers).forEach((column) => {
        for (let i = 0; i < layers[column].length; i++) {
          indexesList.push(layers[column][i].id);
        }
      });

      const startIndex = indexesList.findIndex(
        (item) => item === helperSelectedLayers[helperSelectedLayers.length - 1]
      );

      const endIndex = indexesList.findIndex((item) => item === id);

      const loopStart = Math.min(startIndex, endIndex);
      const loopEnd = Math.max(startIndex, endIndex);

      for (let i = loopStart; i <= loopEnd; i++) {
        if (!helperSelectedLayers.includes(indexesList[i])) {
          helperSelectedLayers.push(indexesList[i]);
        }
      }
    }
  }

  return helperSelectedLayers;
  // setState({ selectedLayers: helperSelectedLayers });
  // setSelectedLayers(helperSelectedLayers);
};

//-----------------------

export const scrollDown = (refWrapper, scrollIntervalSide) => {
  const scrollContainer = refWrapper.current;
  if (!scrollContainer) return;
  const scrollSpeed = 2; // Adjust for faster or slower scrolling

  if (scrollIntervalSide.current === 'up') {
    scrollContainer.scrollBy({ top: -scrollSpeed, behavior: 'smooth' });
  } else {
    scrollContainer.scrollBy({ top: scrollSpeed, behavior: 'smooth' });
  }
};

///--------------------

export const styleBar = (show, refBar) => {
  if (show) {
    refBar.current.style.transition = 'opacity 0.3s';
    refBar.current.style.opacity = '1';
  } else {
    refBar.current.style.transition = 'opacity 0.0s';
    refBar.current.style.opacity = '0';
  }
};
