import React, { useState, useRef, useLayoutEffect } from 'react';

import {
  RangeContianer,
  RangeInfoGrid,
  SliderContainer,
  SliderBackground,
  StyledInput,
  InputStyled,
  TrackLeft,
  TrackRight,
} from './RangeSlider.css';

const RangeSlider = ({
  text = '',
  min = 0,
  max = 100,
  step = 0.1,
  value = 0,
  onChangeValue,
  available = true,
  symbol = '',
  styleBackground,
}) => {
  const lastValidValue = useRef(1.0);

  const refContainer = useRef();
  const refBackground = useRef();

  const onChangeRange = (e) => {
    onChangeValue(e.target.value);

    if (!isNaN(parseFloat(e.target.value))) {
      lastValidValue.current = e.target.value;
    }
  };

  const handleInputChange = (e) => {
    let valueInput = e.target.value;

    let validValue = valueInput;

    if (available) {
      // Zamień przecinki na kropki
      valueInput = valueInput.replace(',', '.');

      // Sprawdź, czy wartość jest liczbą lub kropką
      if (/^-?\d*\.?\d*$/.test(valueInput)) {
        if (parseFloat(valueInput) > max) {
          validValue = max;
          onChangeValue(max);
        } else if (parseFloat(valueInput) < min) {
          validValue = min;
          onChangeValue(min);
        } else {
          validValue = valueInput;
          onChangeValue(valueInput);
        }
      }
    } else {
      onChangeValue(value);
      validValue = value;
    }

    if (!isNaN(parseFloat(validValue))) {
      lastValidValue.current = validValue;
    }
  };

  let rangeValue = value;
  if (typeof rangeValue === 'string' && value.endsWith('.')) {
    rangeValue = parseFloat(value).toFixed(1);
  }

  function calculateThumb(value, min, max, thumbWidth, reversed = false) {
    let getValue = parseFloat(value);

    if (isNaN(getValue)) {
      getValue = lastValidValue.current;
    }

    let trackWidth = 250;
    if (refContainer.current) {
      trackWidth = refContainer.current.getBoundingClientRect().width;
    }

    const percentageOfValue = (getValue - min) / (max - min); // Procent wartości w zakresie
    const percentageOfThumb = thumbWidth / trackWidth; // Procent szerokości kciuka względem toru
    const adjustedPercentage =
      percentageOfValue * (1 - percentageOfThumb) + percentageOfThumb / 2;

    if (reversed) {
      return 100 - adjustedPercentage * 100; // Konwersja na procenty
    } else {
      return adjustedPercentage * 100; // Konwersja na procenty
    }
  }

  useLayoutEffect(() => {
    if (refContainer.current && refBackground.current) {
      const refParent = refContainer.current.getBoundingClientRect();
      refBackground.current.style.backgroundSize = refParent.width + 'px';
    }
  }, []);

  return (
    <>
      {available ? (
        <RangeContianer>
          <RangeInfoGrid>
            <div>{text}</div>
            <div>
              <InputStyled
                type="text"
                value={value}
                onChange={handleInputChange}
                disabled={!available}
              />
              {symbol}
            </div>
          </RangeInfoGrid>

          <SliderContainer ref={refContainer}>
            <SliderBackground ref={refBackground}>
              <TrackLeft
                style={{
                  width: `calc(${calculateThumb(value, min, max, 16)}% - 11px)`,
                  ...styleBackground,
                  backgroundSize: 'inherit',
                }}
              ></TrackLeft>
              <TrackRight
                style={{
                  width: `calc(${calculateThumb(
                    value,
                    min,
                    max,
                    16,
                    true
                  )}% - 11px)`,
                  ...styleBackground,
                  backgroundSize: 'inherit',
                  backgroundPosition: 'right',
                }}
              ></TrackRight>
            </SliderBackground>

            <StyledInput
              type="range"
              min={min}
              max={max}
              value={
                isNaN(parseFloat(value))
                  ? parseFloat(lastValidValue.current)
                  : parseFloat(value)
              }
              step={step}
              onChange={(e) => {
                onChangeRange(e);
              }}
            />
          </SliderContainer>
        </RangeContianer>
      ) : null}
    </>
  );
};

export default RangeSlider;
