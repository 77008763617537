import styled, { css } from 'styled-components';
import { animated } from 'react-spring';
/// ------------------------------------------ Tooltip

export const ToolTipTitleBarWrapper = styled.div`
  display: grid;
  grid-template-columns: 1fr 30px;

  background-color: ${({ theme }) => theme.color.toolTipBackgroundElement};
  color: ${({ theme }) => theme.color.toolTipDark};
  font-weight: bold;

  align-items: center;

  border-radius: 3px 3px 0px 0px;
`;

export const ToolTipTitleText = styled.div`
  font-size: 13px;
  padding-left: 5px;
`;

export const ToolTipTitleIcon = styled.div`
  position: relative;
  display: grid;
  width: 100%;
  height: 100%;

  align-items: center;
  justify-content: center;

  z-index: 1;

  color: ${({ theme }) => theme.color.main};

  cursor: pointer;

  transition: ${({ theme }) => theme.parameter.transition};

  svg {
    path {
      stroke: ${({ theme }) => theme.color.toolTipLight};
      transition: ${({ theme }) => theme.parameter.transition};
    }
  }

  &:hover {
    background-color: ${({ theme }) => theme.color.toolTipLight};

    svg {
      path {
        stroke: ${({ theme }) => theme.color.main};
      }
    }
  }
`;
