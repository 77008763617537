const CrossIcon = ({ width = 10, height = 10 }) => {
  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 10 10"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path d="M1 1L9 9L1 1ZM1 9L9 1L1 9Z" fill="black" />
      <path
        d="M1 1L9 9M1 9L9 1"
        stroke="#C2C2C2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};

export default CrossIcon;
