import { useState } from 'react';

import {
  Wrapper,
  ButtonLabel,
  ButtonWrapper,
  ButtonTick,
  ButtonTextOn,
  ButtonTextOff,
} from './CustomToggleButton.css';

const CustomToogleButton = ({
  title = 'Title',
  value = false,
  onChangeValue,
}) => {
  const handleChangeValue = () => {
    onChangeValue(!value);
  };

  return (
    <Wrapper>
      <ButtonLabel>{title}</ButtonLabel>
      <ButtonWrapper
        className="toggleButtonWrapper"
        $active={value}
        onClick={() => handleChangeValue()}
      >
        <ButtonTextOn className="toggleButtonOn" $active={value}>
          ON
        </ButtonTextOn>
        <ButtonTextOff className="toggleButtonOff" $active={value}>
          OFF
        </ButtonTextOff>
        <ButtonTick $active={value} />
      </ButtonWrapper>
    </Wrapper>
  );
};

export default CustomToogleButton;
