import styled, { css, keyframes } from 'styled-components';

export const RangeContianer = styled.div`
  width: 100%;

  display: grid;
  grid-template-rows: max-content max-content;
  gap: 3px;
  grid-template-columns: 1fr;

  justify-content: center;
  align-content: center;

  grid-auto-flow: column;
`;

export const RangeInfoGrid = styled.div`
  width: 100%;
  display: grid;
  grid-template-columns: max-content max-content;

  justify-content: space-between;

  color: ${({ theme }) => theme.color.toolTipDark};
  font-size: 12px;

  user-select: none;
`;

export const SliderContainer = styled.div`
  width: 100%;
  position: relative;
`;

export const StyledInput = styled.input`
  -webkit-appearance: none;
  width: 100%;
  height: 22px;
  background: transparent;
  outline: none;
  opacity: 1;

  cursor: pointer;
  margin: auto;
  display: block;

  z-index: 3;

  ${({ available }) =>
    available === 'false' &&
    css`
      cursor: auto;
    `};

  &::-webkit-slider-thumb {
    margin-top: 1px;
    transition: ${({ theme }) => theme.parameter.transition};
    position: relative;
    -webkit-appearance: none;
    width: 16px;
    height: 16px;

    border-radius: 50%;
    border: 2px solid ${({ theme }) => theme.color.toolTipLight};
    cursor: pointer;

    z-index: 3;

    ${({ available }) =>
      available === 'false' &&
      css`
        cursor: auto;
      `};

    &:hover {
      border: 2px solid ${({ theme }) => theme.color.toolTipDark};
    }
  }

  &::-moz-range-thumb {
    transition: ${({ theme }) => theme.parameter.transition};
    position: relative;
    width: 16px;
    height: 16px;

    border-radius: 50%;
    border: 2px solid ${({ theme }) => theme.color.toolTipDark};
    cursor: pointer;

    z-index: 3;

    ${({ available }) =>
      available === 'false' &&
      css`
        cursor: auto;
      `};

    &:hover {
      border: 2px solid ${({ theme }) => theme.color.toolTipDark};
    }
  }

  &::-webkit-slider-track {
    z-index: 3;
  }

  &::-moz-range-track {
    z-index: 3;
  }

  &:hover::-webkit-slider-thumb {
    /* border-color: white; */
  }

  &:active::-webkit-slider-thumb {
    box-shadow: inset 0 0 2px 1px ${({ theme }) => theme.color.toolTipLight};
  }
`;

export const InputStyled = styled.input`
  font-size: 12px;
  outline: none;
  border: 0px;

  background: transparent;

  color: ${({ theme }) => theme.color.toolTipDark};

  width: 100%;
  max-width: 40px;
  box-sizing: border-box;

  text-align: right;
`;

export const SliderBackground = styled.div`
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  width: 100%;

  height: 2px;

  display: inline-block;
  box-sizing: border-box;
  background-size: 150px;
  pointer-events: none;
  touch-action: none;
`;

export const TrackLeft = styled.div`
  background: ${({ theme }) => theme.color.toolTipBackground};
  background-size: inherit;

  box-sizing: border-box;
  position: absolute;
  left: 0px;
  height: 100%;
  top: 0px;
`;

export const TrackRight = styled.div`
  background: ${({ theme }) => theme.color.toolTipBackground};
  background-position: right;
  box-sizing: border-box;
  background-size: inherit;

  position: absolute;
  right: 0px;
  height: 100%;
  top: 0px;
`;
