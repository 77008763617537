import { create } from 'zustand';
import { v4 as uuidv4 } from 'uuid';
import { shallow } from 'zustand/shallow';
import { createRef } from 'react';

// import { apiProductResponse } from './apiProductResponse';
import {
  enumLayerType,
  enumViewMode,
  enumWorkspaceModes,
  enumTabLeftSideNavigator,
} from './enums';
import {
  scaleToZoom,
  zoomToScale,
  selectObjectsByLayers,
  resolutionProps,
  removeElementFromFabric,
  cloneElementfromFabric,
} from 'layout/configurator/rightSide/workspace/components/fabric/utils/utils';
import {
  removeElementFromLayers,
  addNewLayer,
  findLayerItem,
} from 'layout/configurator/leftSide/tabsLeftSide/tabArea/testLayer4/utils/utils';

import {
  calculateMm,
  findClipPathReference,
} from 'layout/configurator/rightSide/workspace/components/three/scene/Model/printArea/utils';
import { deselectLogic } from 'layout/configurator/leftSide/tabsLeftSide/tabArea/testLayer4/utils/sortableFunctions';

import { isFocusNotOnSpecifiedElements } from './utils';

export const enumSelectLayerType = {
  ID: 'ID',
  INDEX: 'INDEX',
  CLEAR: 'CLEAR',
};

const fabricSettings = {
  workspaceSize: { width: 1024, height: 1024 },
  grid: {
    visible: true,
    horizontal: 50,
    vertical: 50,
    color: '#ccc',
    strokeWidth: 1,
  },
  workspaceSizeBorder: {
    visible: true,
    color: '#ffffff',
    fill: 'rgba(255,255,255,0)',
    strokeWidth: 2,
  },
};

const useStoreImpl = create((set, get) => {
  const actions = {
    selectLayer: (id, group, multiselect) => {
      // find proper id or index depends of selection type and fill rest of the selected values
      const { layers, selectedLayers, refFabric, refTextureFabricArray } =
        get();

      let helper = [...selectedLayers];

      if (multiselect) {
        let add = true;
        for (let i = 0; i < helper.length; i++) {
          if (helper[i] === id) {
            helper.splice(i, 1);
            add = false;
            break;
          }
        }
        if (add) {
          helper.push(id);
        }

        if (helper.length > 1) {
          const LockedAndSelectedList = [];
          const updatedLayers = { ...layers };

          Object.keys(updatedLayers).forEach((sectionKey) => {
            updatedLayers[sectionKey].forEach((item) => {
              if (item.islocked && helper.includes(item.id)) {
                LockedAndSelectedList.push(item.id);
              }
            });
          });

          const filteredLockedAndSelected = LockedAndSelectedList.filter(
            (layer) => helper.includes(layer)
          );

          if (filteredLockedAndSelected.length > 0) {
            helper = [id];
          }
        }
      } else {
        if (helper.length > 1) {
          helper = [id];
        } else if (helper.length === 1) {
          if (helper[0] === id) {
            helper = [];
          } else {
            helper = [id];
          }
        } else {
          helper = [id];
        }
      }

      //---------- select workspace
      if (refFabric.current) {
        selectObjectsByLayers(refFabric.current, helper);
        refFabric.current.requestRenderAll();
      }

      //---------- select texture fabric
      refTextureFabricArray.forEach((textureFabric) => {
        selectObjectsByLayers(textureFabric.fabricRef.current, helper);
        textureFabric.fabricRef.current.requestRenderAll();
      });

      set({
        selectedLayers: helper,
      });
    },
    closeModals: () => {
      set({
        modalProductsOpen: false,
        modalSettingsOpen: false,
        modalProjectsAndGraphicsOpen: false,
      });
    },
    setFabricScale: () => {
      const { refFabric, fabricScale, actions } = get();

      const fabricObj = refFabric.current;
      if (fabricObj) {
        // Skalujemy liniowo wartość 1-200 na zakres zoomu 0.01-20
        let zoom = scaleToZoom(fabricScale);

        // const zoom = fabricScale / 10;

        // Środek bieżącego viewportu
        const center = {
          x: fabricObj.width / 2 - fabricObj.viewportTransform[4],
          y: fabricObj.height / 2 - fabricObj.viewportTransform[5],
        };

        // Oblicz pozycję środka widoku względem bieżącego zoomu
        const centerX = center.x / fabricObj.getZoom();
        const centerY = center.y / fabricObj.getZoom();

        // Ustaw nowy zoom
        fabricObj.setZoom(zoom);

        // Oblicz nowe przesunięcie tak, aby środek pozostał na miejscu
        const newCenterX = fabricObj.width / 2 - centerX * zoom;
        const newCenterY = fabricObj.height / 2 - centerY * zoom;

        fabricObj.viewportTransform[4] = newCenterX;
        fabricObj.viewportTransform[5] = newCenterY;

        fabricObj.requestRenderAll();

        actions.scrollBarsCanvas();
      }
    },

    centerCanvas: () => {
      const { fabricSettings, refFabric, actions } = get();

      const fabricObj = refFabric.current;
      if (fabricObj) {
        // Załóżmy, że tak odwołujesz się do obiektu canvas Fabric.js
        const canvasWidth = fabricObj.getWidth();
        const canvasHeight = fabricObj.getHeight();

        const strokeWidth = fabricSettings.workspaceSizeBorder.strokeWidth;

        const positionAlignBorder =
          strokeWidth > 1 ? strokeWidth - 1 : strokeWidth;
        const borderSize = strokeWidth * 2;
        const padding = 100;

        // console.log(canvasWidth, canvasHeight);

        // Oblicz wymagany zoom, aby obszar 1024x1024 był widoczny na canvas
        const zoomX =
          canvasWidth /
          (fabricSettings.workspaceSize.width + borderSize + padding);
        const zoomY =
          canvasHeight /
          (fabricSettings.workspaceSize.height + borderSize + padding);

        const newZoom = Math.min(zoomX, zoomY);

        fabricObj.setZoom(newZoom);

        set({ fabricScale: zoomToScale(newZoom).toFixed() + '' });

        // Oblicz nowe przesunięcie, aby centrum obszaru roboczego 1024x1024 było na środku canvas
        const newCenterX =
          canvasWidth / 2 -
          ((fabricSettings.workspaceSize.width + borderSize) * newZoom) / 2;
        const newCenterY =
          canvasHeight / 2 -
          ((fabricSettings.workspaceSize.height + borderSize) * newZoom) / 2;

        fabricObj.viewportTransform[4] = newCenterX + positionAlignBorder;
        fabricObj.viewportTransform[5] = newCenterY + positionAlignBorder + 5;

        fabricObj.requestRenderAll();

        actions.scrollBarsCanvas();
      }
    },
    resizeCanvas: () => {
      const { refFabric, refFabricContainer, actions } = get();

      const fabricObj = refFabric.current;
      const container = refFabricContainer.current;
      if (fabricObj && container) {
        const zoom = fabricObj.getZoom();

        const oldCenterX =
          (fabricObj.getWidth() / 2 - fabricObj.viewportTransform[4]) / zoom;
        const oldCenterY =
          (fabricObj.getHeight() / 2 - fabricObj.viewportTransform[5]) / zoom;

        // Zaktualizuj rozmiary płótna
        fabricObj.setHeight(container.clientHeight);
        fabricObj.setWidth(container.clientWidth);

        // Oblicz nowe panningi, aby środek widoku pozostał w tym samym miejscu
        const newPanX = fabricObj.getWidth() / 2 - oldCenterX * zoom;
        const newPanY = fabricObj.getHeight() / 2 - oldCenterY * zoom;

        fabricObj.setViewportTransform([zoom, 0, 0, zoom, newPanX, newPanY]);
        fabricObj.renderAll();

        actions.scrollBarsCanvas();
      }
    },
    scrollBarsCanvas: () => {
      const { refFabric } = get();

      const fabricObj = refFabric.current;

      if (fabricObj) {
        const verticalScrollbar = document.getElementById(
          'vertical-scrollbar-thumb'
        );
        const horizontalScrollbar = document.getElementById(
          'horizontal-scrollbar-thumb'
        );

        if (verticalScrollbar && horizontalScrollbar) {
          const canvasHeight = fabricObj.getHeight();
          const canvasWidth = fabricObj.getWidth();
          const zoom = fabricObj.getZoom();
          const scrollTop = -fabricObj.viewportTransform[5];
          const scrollLeft = -fabricObj.viewportTransform[4];

          // Stała wysokość obszaru roboczego
          const workspaceHeight = fabricSettings.workspaceSize.height;
          const workspaceWidth = fabricSettings.workspaceSize.width;

          // Wysokość obszaru roboczego po przeskalowaniu przez zoom
          const scaledWorkspaceHeight = workspaceHeight * zoom;
          const scaledWorkspaceWidth = workspaceWidth * zoom;

          let totalHeight = canvasHeight;
          let totalWidth = canvasWidth;

          if (scrollTop > 0) {
            // console.log(
            //   'zmniejsz scrolla - obszar roboczy poza górną krawedzią canvasa'
            // );
            if (scaledWorkspaceHeight - scrollTop < canvasHeight) {
              // console.log('merzymy do dolnej krawedzi canvasa');

              totalHeight = Math.abs(scrollTop) + canvasHeight;
            } else {
              // console.log('merzymy do dolnej krawedzi workspace');

              totalHeight = scaledWorkspaceHeight;
            }
          } else if (scrollTop < scaledWorkspaceHeight - canvasHeight) {
            // console.log(
            //   'zmniejsz scrolla - obszar roboczy poza viewportem dolna krawedź'
            // );
            const WorkspaceOutofView =
              scaledWorkspaceHeight - scrollTop - canvasHeight;

            if (scaledWorkspaceHeight - WorkspaceOutofView < canvasHeight) {
              // console.log('merzymy do górnej krawedzi canvasa');
              totalHeight = Math.abs(scrollTop) + scaledWorkspaceHeight;
            }
          }

          const scrollbarThumbHeight = canvasHeight ** 2 / totalHeight;

          if (scrollLeft > 0) {
            // console.log(
            //   'zmniejsz scrolla - obszar roboczy poza górną krawedzią canvasa'
            // );
            if (scaledWorkspaceWidth - scrollLeft < canvasWidth) {
              // console.log('merzymy do dolnej krawedzi canvasa');

              totalWidth = Math.abs(scrollLeft) + canvasWidth;
            } else {
              // console.log('merzymy do dolnej krawedzi workspace');

              totalWidth = scaledWorkspaceWidth;
            }
          } else if (scrollLeft < scaledWorkspaceWidth - canvasWidth) {
            // console.log(
            //   'zmniejsz scrolla - obszar roboczy poza viewportem dolna krawedź'
            // );
            const WorkspaceOutofView =
              scaledWorkspaceWidth - scrollLeft - canvasWidth;

            if (scaledWorkspaceWidth - WorkspaceOutofView < canvasWidth) {
              // console.log('merzymy do górnej krawedzi canvasa');
              totalWidth = Math.abs(scrollLeft) + scaledWorkspaceWidth;
            }
          }

          const scrollbarThumbWidth = canvasWidth ** 2 / totalWidth;

          // console.log(scrollbarThumbHeight, canvasHeight);

          if (scrollbarThumbHeight >= canvasHeight) {
            verticalScrollbar.style.display = 'none';
          } else {
            verticalScrollbar.style.display = 'block';
          }

          if (scrollbarThumbWidth >= canvasWidth) {
            horizontalScrollbar.style.display = 'none';
          } else {
            horizontalScrollbar.style.display = 'block';
          }

          verticalScrollbar.style.height = `${scrollbarThumbHeight}px`;
          horizontalScrollbar.style.width = `${scrollbarThumbWidth}px`;

          /////////////////---------------------------------------------------
          let topContentPosition = 0;

          if (scrollTop > 0) {
            topContentPosition = Math.abs(scrollTop);
          }

          let positionTop = 0;

          if (totalHeight > canvasHeight) {
            positionTop =
              (topContentPosition / (totalHeight - canvasHeight)) *
              (canvasHeight - scrollbarThumbHeight);
          }

          verticalScrollbar.style.top = `${positionTop}px`;

          let leftContentPosition = 0;

          if (scrollLeft > 0) {
            leftContentPosition = Math.abs(scrollLeft);
          }

          let positionLeft = 0;

          if (totalWidth > canvasWidth) {
            positionLeft =
              (leftContentPosition / (totalWidth - canvasWidth)) *
              (canvasWidth - scrollbarThumbWidth);
          }

          horizontalScrollbar.style.left = `${positionLeft}px`;
        }
      }
    },
    moveArrow: (arrowDirection) => {
      const {
        refFabric,
        refTextureFabricArray,
        qualityId,
        fabricSettings,
        design,
        selectedProductOptionsVariantId,
        workspacesMode,
      } = get();

      if (isFocusNotOnSpecifiedElements()) {
        refTextureFabricArray.forEach((textureFabric) => {
          const activeGroup = textureFabric.fabricRef.current.getActiveObject();
          if (activeGroup) {
            // collect resolutions
            const { textureSize, workspaceWidth, workspaceHeight } =
              resolutionProps(qualityId, fabricSettings);

            if (arrowDirection === 'ArrowUp') {
              activeGroup.set({
                top: activeGroup.top - 2 * (textureSize / workspaceWidth),
              });
            } else if (arrowDirection === 'ArrowDown') {
              activeGroup.set({
                top: activeGroup.top + 2 * (textureSize / workspaceWidth),
              });
            } else if (arrowDirection === 'ArrowLeft') {
              activeGroup.set({
                left: activeGroup.left - 2 * (textureSize / workspaceWidth),
              });
            } else if (arrowDirection === 'ArrowRight') {
              activeGroup.set({
                left: activeGroup.left + 2 * (textureSize / workspaceWidth),
              });
            }

            const findModel = design.productDesignLoadedData.find(
              (item) => item.idVariant === selectedProductOptionsVariantId
            );

            const calibrationValue = findModel.printAreas.find(
              (item) => item.id === textureFabric.id
            );

            set({
              worksapcePositionX:
                calculateMm(
                  activeGroup.left * (workspaceWidth / textureSize),
                  calibrationValue.calibrationSize
                ).toFixed(4) + ' mm',
              worksapcePositionY:
                calculateMm(
                  activeGroup.top * (workspaceWidth / textureSize),
                  calibrationValue.calibrationSize
                ).toFixed(4) + ' mm',

              // worksapcePositionX: (
              //   activeGroup.left *
              //   (workspaceWidth / textureSize)
              // ).toFixed(4),
              // worksapcePositionY: (
              //   activeGroup.top *
              //   (workspaceWidth / textureSize)
              // ).toFixed(4),
            });

            if (workspacesMode.mode === enumWorkspaceModes.EDIT_PRINT_AREAS) {
              if (textureFabric.fabricRef.current.clippingShape) {
                textureFabric.fabricRef.current.clippingShape.left =
                  activeGroup.left;
                textureFabric.fabricRef.current.clippingShape.top =
                  activeGroup.top;
              }

              ///------- object

              const findClipPath = findClipPathReference(textureFabric.id);

              if (findClipPath) {
                findClipPath.clipPath.data.left =
                  activeGroup.left * (workspaceWidth / textureSize);
                findClipPath.clipPath.data.top =
                  activeGroup.top * (workspaceWidth / textureSize);
              }
            }

            textureFabric.fabricRef.current.requestRenderAll();
          }
        });

        if (refFabric.current) {
          const activeGroup = refFabric.current.getActiveObject();
          if (activeGroup) {
            if (arrowDirection === 'ArrowUp') {
              activeGroup.set({ top: activeGroup.top - 2 });
            } else if (arrowDirection === 'ArrowDown') {
              activeGroup.set({ top: activeGroup.top + 2 });
            } else if (arrowDirection === 'ArrowLeft') {
              activeGroup.set({ left: activeGroup.left - 2 });
            } else if (arrowDirection === 'ArrowRight') {
              activeGroup.set({ left: activeGroup.left + 2 });
            }

            if (workspacesMode.mode === enumWorkspaceModes.EDIT_PRINT_AREAS) {
              if (refFabric.current.clippingShape) {
                refFabric.current.clippingShape.left = activeGroup.left;
                refFabric.current.clippingShape.top = activeGroup.top;
              }
            }

            refFabric.current.requestRenderAll();
          }
        }
      }
    },
    cancelAction: () => {
      const { workspacesMode, refFabric, selectedTabLeftSide } = get();

      deselectLogic(set);

      if (workspacesMode.mode === enumWorkspaceModes.ADD_NEW_OBJECT) {
        set({ workspacesMode: { mode: enumWorkspaceModes.STANDARD } });
      } else if (workspacesMode.mode === enumWorkspaceModes.EDIT_PRINT_AREAS) {
        console.log('here', selectedTabLeftSide);

        if (selectedTabLeftSide === enumTabLeftSideNavigator.PRINT_AREAS) {
          set({ selectedTabLeftSide: null });
        }

        set({ workspacesMode: { mode: enumWorkspaceModes.STANDARD } });

        if (refFabric.current) {
          refFabric.current.getObjects().forEach(function (object) {
            if (object.type === 'IMAGE' || object.type === 'TEXT') {
              object.selectable = true;
              object.evented = true;
            } else if (object.id.startsWith('clipPath_')) {
              object.selectable = false;
              object.evented = false;
            }
          });
        }
      }

      if (refFabric.current) {
        refFabric.current.mode = enumWorkspaceModes.STANDARD;

        refFabric.current.defaultCursor = 'default';
        refFabric.current.selection = true;

        refFabric.current.forEachObject(function (object) {
          if (
            object.type === 'SKIP' ||
            object.id === 'workspaceBorder' ||
            object.id.startsWith('clipPath_')
          ) {
            if (object.id.startsWith('clipPath_')) {
              object.selectable = false;
              object.evented = false;
            }
          } else {
            object.selectable = true;
            object.evented = true;
          }
        });

        refFabric.current.requestRenderAll();
      }

      set({
        worksapceToolToolTipTextEdit: { open: false, newObject: false },
        worksapceToolToolTipImageEdit: { open: false },
        worksapceToolToolTipTranslation: { open: false, objectType: null },
        worksapceToolToolTipTextFillColor: { open: false },
        worksapceToolToolTipTextBorderColor: { open: false },
        worksapceToolToolTipTextFont: { open: false },
        worksapceToolToolTipImageFilters: { open: false },
        worksapceToolToolTipImageFillColor: { open: false },
        worksapceToolToolTipTextShadow: { open: false },
        worksapceToolToolTipClipPathShape: { open: false },
      });
    },
    removeSelectedObjects: () => {
      const {
        refFabric,
        refTextureFabricArray,
        layers,
        layersInitial,
        workspacesMode,
      } = get();

      if (workspacesMode.mode !== enumWorkspaceModes.EDIT_PRINT_AREAS) {
        const updatedLayers = { ...layers };
        const helperInitialLayers = [...layersInitial];

        // ------ remove from texture fabric
        refTextureFabricArray.forEach((textureFabric) => {
          const activeGroup = textureFabric.fabricRef.current.getActiveObject();
          if (activeGroup) {
            if (activeGroup.type === 'activeSelection') {
              activeGroup.forEachObject(function (obj) {
                removeElementFromFabric(
                  textureFabric.fabricRef.current,
                  obj.id
                );
              });
              textureFabric.fabricRef.current.discardActiveObject();
              textureFabric.fabricRef.current.requestRenderAll();

              // ------ remove from Layers

              activeGroup.forEachObject(function (obj) {
                removeElementFromLayers(
                  updatedLayers,
                  helperInitialLayers,
                  obj.id
                );
              });
            } else {
              removeElementFromFabric(
                textureFabric.fabricRef.current,
                activeGroup.id
              );
              textureFabric.fabricRef.current.discardActiveObject();
              textureFabric.fabricRef.current.requestRenderAll();

              removeElementFromLayers(
                updatedLayers,
                helperInitialLayers,
                activeGroup.id
              );
            }
          }
        });

        // ------ remove from workspace
        if (refFabric.current) {
          const activeGroup = refFabric.current.getActiveObject();

          if (activeGroup) {
            if (activeGroup.type === 'activeSelection') {
              activeGroup.forEachObject(function (obj) {
                removeElementFromFabric(refFabric.current, obj.id);
              });
              refFabric.current.discardActiveObject();
              refFabric.current.requestRenderAll();
            } else {
              removeElementFromFabric(refFabric.current, activeGroup.id);
              refFabric.current.discardActiveObject();
              refFabric.current.requestRenderAll();
            }
          }
        }

        set({
          layers: updatedLayers,
          layersInitial: helperInitialLayers,
          worksapceToolToolTipTextEdit: { open: false, newObject: false },
          worksapceToolToolTipImageEdit: { open: false },
          worksapceToolToolTipTranslation: { open: false, objectType: null },
          worksapceToolToolTipTextFillColor: { open: false },
          worksapceToolToolTipTextBorderColor: { open: false },
          worksapceToolToolTipTextFont: { open: false },
          worksapceToolToolTipImageFilters: { open: false },
          worksapceToolToolTipImageFillColor: { open: false },
          worksapceToolToolTipTextShadow: { open: false },
          worksapceToolToolTipClipPathShape: { open: false },
        });
      }
    },
    copyObjectsToClippboard: () => {
      const {
        refFabric,
        refTextureFabricArray,
        layers,
        layersInitial,
        workspacesMode,
        qualityId,
        fabricSettings,
      } = get();
      if (isFocusNotOnSpecifiedElements()) {
        if (workspacesMode.mode !== enumWorkspaceModes.EDIT_PRINT_AREAS) {
          let clipboardHelper = [];

          const { textureSize, workspaceWidth, workspaceHeight } =
            resolutionProps(qualityId, fabricSettings);

          // ------ copy from texture fabric
          refTextureFabricArray.forEach((textureFabric) => {
            const activeGroup =
              textureFabric.fabricRef.current.getActiveObject();

            if (activeGroup) {
              if (activeGroup.type === 'activeSelection') {
                try {
                  set(async (state) => {
                    const clonedObjectsTextureFabric = [];
                    const clonedLayers = [];

                    // copy layers
                    activeGroup.forEachObject(function (obj) {
                      const layerItem = findLayerItem(layers, obj.id);

                      const addLayer = addNewLayer(
                        obj.id,
                        obj.id,
                        layerItem.type,
                        layerItem.islocked,
                        layerItem.title,
                        layerItem.props,
                        layerItem.visible
                      );

                      clonedLayers.push(addLayer);
                    });

                    // copy fabric texture

                    const groupHelper = {
                      angle:
                        textureFabric.fabricRef.current.getActiveObject().angle,
                      scaleX:
                        textureFabric.fabricRef.current.getActiveObject()
                          .scaleX *
                        (workspaceWidth / textureSize),
                      scaleY:
                        textureFabric.fabricRef.current.getActiveObject()
                          .scaleY *
                        (workspaceWidth / textureSize),
                    };

                    const objectProps = [];
                    activeGroup.forEachObject((obj) => {
                      objectProps.push({
                        id: obj.id,
                        left: obj.left,
                        top: obj.top,
                        angle: obj.angle,
                        scaleX: obj.scaleX,
                        scaleY: obj.scaleY,
                        skewX: obj.skewX,
                        skewY: obj.skewY,
                        flipX: obj.flipX,
                        flipY: obj.flipY,
                      });
                    });

                    const clonePromises = activeGroup
                      .getObjects()
                      .map(async (obj, index) => {
                        const cloned1 = await cloneElementfromFabric(
                          textureFabric.fabricRef.current,
                          obj.id,
                          obj.id,
                          0
                        );

                        if (cloned1) {
                          if (textureFabric.fabricRef.current.clippingShape) {
                            cloned1.clipPath =
                              textureFabric.fabricRef.current.clippingShape;
                          }

                          const findProps = objectProps.find(
                            (clonedobj) => clonedobj.id === obj.id
                          );

                          if (findProps) {
                            cloned1.set({
                              left: findProps.left,
                              top: findProps.top,
                              angle: findProps.angle,
                              scaleX: findProps.scaleX,
                              scaleY: findProps.scaleY,
                              skewX: findProps.skewX,
                              skewY: findProps.skewY,
                              flipX: findProps.flipX,
                              flipY: findProps.flipY,
                            });
                          }

                          clonedObjectsTextureFabric.push(cloned1);
                        }
                      });

                    Promise.all(clonePromises).then(() => {
                      clipboardHelper.push({
                        fabricId: textureFabric.id,
                        group: true,
                        groupProps: groupHelper,
                        elements: clonedObjectsTextureFabric,
                        layers: clonedLayers,
                      });

                      console.log(clipboardHelper);

                      set({ clipboard: clipboardHelper });
                    });
                  });
                } catch (error) {
                  console.error('Error cloning object:', error);
                }
              } else {
                try {
                  set(async (state) => {
                    const clonedObjectsTextureFabric = [];
                    const clonedLayers = [];

                    // copy layers

                    const layerItem = findLayerItem(layers, activeGroup.id);

                    const addLayer = addNewLayer(
                      activeGroup.id,
                      activeGroup.id,
                      layerItem.type,
                      layerItem.islocked,
                      layerItem.title,
                      layerItem.props,
                      layerItem.visible
                    );

                    clonedLayers.push(addLayer);

                    // copy fabric texture
                    const clonePromises = activeGroup;

                    const cloned1 = await cloneElementfromFabric(
                      textureFabric.fabricRef.current,
                      activeGroup.id,
                      activeGroup.id,
                      0
                    );

                    if (cloned1) {
                      if (textureFabric.fabricRef.current.clippingShape) {
                        cloned1.clipPath =
                          textureFabric.fabricRef.current.clippingShape;
                      }

                      cloned1.set({
                        scaleX: cloned1.scaleX * (workspaceWidth / textureSize),
                        scaleY: cloned1.scaleY * (workspaceWidth / textureSize),
                      });

                      clonedObjectsTextureFabric.push(cloned1);
                    }

                    clipboardHelper.push({
                      fabricId: textureFabric.id,
                      group: false,
                      groupProps: null,
                      elements: clonedObjectsTextureFabric,
                      layers: clonedLayers,
                    });

                    console.log(clipboardHelper);

                    set({ clipboard: clipboardHelper });
                  });
                } catch (error) {
                  console.error('Error cloning object:', error);
                }
              }
            }
          });
        }
      }
    },
    pasteObjectsFromClippboard: () => {
      const { clipboard, refFabric, refTextureFabricArray, workspacesMode } =
        get();

      if (isFocusNotOnSpecifiedElements()) {
        if (workspacesMode.mode !== enumWorkspaceModes.EDIT_PRINT_AREAS) {
          if (clipboard.length > 0) {
            console.log('paste');

            if (refFabric.current) {
              refFabric.current.mode = enumWorkspaceModes.ADD_NEW_OBJECT;

              refFabric.current.selection = false;

              refFabric.current.forEachObject(function (object) {
                object.selectable = false;
                object.evented = false;
              });

              refFabric.current.discardActiveObject().requestRenderAll();

              refFabric.current.defaultCursor = 'crosshair';
            }

            for (const textureFabric of refTextureFabricArray) {
              textureFabric.fabricRef.current
                .discardActiveObject()
                .requestRenderAll();
              textureFabric.fabricRef.current.requestRenderAll();
            }

            set({
              selectedLayers: [],
              workspacesMode: {
                mode: enumWorkspaceModes.ADD_NEW_OBJECT,
                type: enumLayerType.PASTE_FROM_CLIPBOARD,
              },
            });
          }
        }
      }
    },
  };

  /// Viewmodes = NOT_FOUND UNAUTHORIZED OWNER GUEST
  /// editMode = NONE OWNER_EDIT GUEST_VIEW GUEST_EDIT

  return {
    actions,
    get,
    set,
    ShareViewMode: null,
    ShareEditMode: 'NONE',

    modalGlobalMessage: {
      visible: false,
      type: null,
      loadingIndicator: false,
      uploadingProgress: 0,
      message: '',
    },

    viewMode: 0,
    themeId: 0,
    qualityId: null,
    currencyId: 0,
    languageId: 0,
    fabricSelectedPrintArea: null,
    refThree: createRef(),
    refFabricContainer: createRef(),

    showShadow: true,

    shapeKeys: false,
    space3dEdit: true,
    animationPose: 1,

    refFabric: createRef(),
    refTextureFabricArray: [],
    refTextureFabricArrayForceRefresh: 0,
    enabledFabricList: [],
    currentClipPathList: [],
    currentLoadedLayers: [],
    currentShareOptions: {
      idView: 1,
      idAccess: 1,
      shareEmails: [],
      adultContent: false,
    },
    currentDesignName: '',

    workspacesMode: { mode: enumWorkspaceModes.STANDARD },
    workspaceFabricUpdate: false,
    clipboard: [],

    fabricScale: '100',
    fabricPan: { x: 0, y: 0 },
    fabricSettings,
    shoppingCart: [
      {
        productId: 0,
        colorId: 0,
        size: [
          { id: 0, amount: 0 },
          { id: 1, amount: 0 },
          { id: 2, amount: 0 },
          { id: 3, amount: 0 },
          { id: 4, amount: 0 },
        ],
        materialId: 0,
        printTypeId: 0,
        printLayers: 2,
      },
      {
        productId: 0,
        colorId: 0,
        size: [
          { id: 0, amount: 0 },
          { id: 1, amount: 0 },
          { id: 2, amount: 0 },
          { id: 3, amount: 0 },
          { id: 4, amount: 0 },
        ],
        materialId: 0,
        printTypeId: 0,
        printLayers: 2,
      },
      {
        productId: 0,
        colorId: 0,
        size: [
          { id: 0, amount: 0 },
          { id: 1, amount: 0 },
          { id: 2, amount: 0 },
          { id: 3, amount: 0 },
          { id: 4, amount: 0 },
        ],
        materialId: 0,
        printTypeId: 0,
        printLayers: 2,
      },
    ],
    selectedTabLeftSide: null,
    selectedTabRightSide: null,
    // selectedProduct: apiProductResponse[0],
    design: null,
    initialLoadingTextureFabric: [],
    initialFabricLoaders: false,

    selectedProductOptionsVariantId: null,
    selectedProductOptionsModelId: null,
    selectedProductOptionsMaterialId: null,
    selectedProductOptionsPrintTypeId: null,
    selectedProductOptionsColorId: null,
    selectedProductOptionsColorBlend: '#ffffff',

    selectedProductOptions: {
      colorId: null,
      size: [
        { id: 0, amount: 0 },
        { id: 1, amount: 0 },
        { id: 2, amount: 0 },
        { id: 3, amount: 0 },
        { id: 4, amount: 0 },
      ],
      materialId: null,
      printTypeId: null,
    },
    selectedLayers: [],

    layersInitial: [],
    layers: [],
    hasPointerLayersMoved: false,
    modalProductsOpen: false,
    modalSettingsOpen: false,
    modalExportLayersOpen: false,
    modalExportLayersPrintAreaId: 0,
    modalRenderSceneOpen: false,
    modalHelperOpen: false,

    modalProjectsAndGraphicsOpen: false,
    modalBackgroundMaskingOpen: false,
    forceRefreshingToolTipTools: 0,
    worksapceToolToolTipTextEdit: { open: false, newObject: false },
    worksapceToolToolTipImageEdit: { open: false },
    worksapceToolToolTipImageFilters: { open: false },
    worksapceToolToolTipImageFillColor: { open: false },
    worksapceToolToolTipTranslation: { open: false, objectType: null },
    worksapceToolToolTipTextFillColor: { open: false },
    worksapceToolToolTipTextBorderColor: { open: false },
    worksapceToolToolTipTextFont: { open: false },
    worksapceToolToolTipTextShadow: { open: false },
    worksapceToolToolTipClipPathShape: { open: false },
    worksapceToolAngle: 0,
    worksapceScaleX: 1,
    worksapceScaleY: 1,
    worksapceSizeX: 0,
    worksapceSizeY: 0,
    worksapcePositionX: 0,
    worksapcePositionY: 0,

    modalLibraryQRURL: '',
    modalLibraryQRPreset: 0,
    modalLibraryDotsTypeColor: 0,
    modalLibrarydotsColor1: { r: 0, g: 0, b: 0, a: 1 },
    modalLibrarydotsColor2: { r: 0, g: 0, b: 0, a: 1 },
    modalLibraryQRdotsLinearRotation: '0.0',

    modalLibraryQRCornersSquereTypeColor: 3,
    modalLibraryCornersSquereColor1: { r: 0, g: 0, b: 0, a: 1 },
    modalLibraryCornersSquereColor2: { r: 0, g: 0, b: 0, a: 1 },
    modalLibraryQRCornersSquereLinearRotation: '0.0',

    modalLibraryQRCornersDotTypeColor: 3,
    modalLibraryCornersDotColor1: { r: 0, g: 0, b: 0, a: 1 },
    modalLibraryCornersDotColor2: { r: 0, g: 0, b: 0, a: 1 },
    modalLibraryQRCornersDotLinearRotation: '0.0',

    modalLibraryQRBackgroundTypeColor: 3,
    modalLibraryBackgroundColor1: { r: 0, g: 0, b: 0, a: 1 },
    modalLibraryBackgroundColor2: { r: 0, g: 0, b: 0, a: 1 },
    modalLibraryQRBackgroundLinearRotation: '0.0',

    modalLibraryQRImageHole: 0,

    threeRefCamera: createRef(),
    isCtrlPressed: false,
    isShiftPressed: false,
    threeDraggedElements: {
      meshId: null,
    },

    refCursorDescription: createRef(),

    renderSceneEnabled: false,
    wasRendered: false,
    renderStartShadow: false,
    renderPauseShadow: false,
    threeRefCameraRender: createRef(),

    refThreeRender: createRef(),
    renderGizmoRef: createRef(),

    renderSelectBoxScenerySetupOption: 0,
    renderEnvironmentBlurines: 0.01,
    renderSelectBoxLightingPresetOption: 1,
    renderEnvironmentIntensity: 1,
    renderSceneRotationY: 0,
    renderLightBounceCount: 2,
    renderSamplesCount: 15,
    renderResolutionCount: 2,
    renderSelectBoxFrameRenderLimitOption: 3,

    finalRenderSettings: {
      renderSelectBoxScenerySetupOption: 0,
      renderEnvironmentBlurines: 0.01,
      renderSelectBoxLightingPresetOption: 1,
      renderEnvironmentIntensity: 1,
      renderLightRotation: 0,
      renderLightBounceCount: 2,
      renderSamplesCount: 15,
      renderResolutionCount: 2,
      renderSelectBoxFrameRenderLimitOption: 3,
    },
    renderTimerCounter: createRef(),
    renderSampleCounter: createRef(),
    renderSampleProgressBar: createRef(),
    refRenderSceneGroup: createRef(),
    forceRenderReset: 0,
    renderCameraHoryzontalRotation: 0,
    renderCameraVerticalRotation: 0,
    renderCameraDistance: '0.00',

    renderCameraCenterPointX: '0.00',
    renderCameraCenterPointY: '0.00',
    renderCameraCenterPointZ: '0.00',
  };
});

export const mutation = {
  threeDraggedElements: { meshId: null, elements: null },
  renderFrames: 0,
  renderTimeStart: null,
  renderEndTime: null,
  refreshThreeMaps: {},
  workspaceClickAction: null,
};

// Make the store shallow compare by default
const useStore = (sel) => useStoreImpl(sel, shallow);
Object.assign(useStore, useStoreImpl);

const { getState, setState } = useStoreImpl;

export { getState, setState, useStore };
