import styled, { css } from 'styled-components';

export const TopToolsWrapper = styled.div`
  /* position: absolute;
  top: 8px;
  left: 8px; */
  /* display: grid; */
  /* grid-template-columns: auto auto; */
  /* gap: 8px; */
  height: 100%;
`;

export const Wrapper = styled.div`
  border-radius: 3px;

  /* background-color: ${({ theme }) => theme.color.canvasToolsBackground}; */

  display: flex;
  height: 100%;

  user-select: none;
`;

export const IconContainer = styled.div`
  /* &:first-child {
    border-radius: 3px 0px 0px 3px;
  }
  &:last-child {
    border-radius: 0px 3px 3px 0px;
  } */

  display: grid;
  cursor: pointer;
  transition: ${({ theme }) => theme.parameter.transition};

  &:hover {
    background-color: ${({ theme }) =>
      theme.color.backgroundLayoutHoverNotSelected};
  }

  /* background-color: ${({ theme }) =>
    theme.color.backgroundLayoutHoverNotSelected}; */

  align-items: center;
  justify-content: center;
  min-width: 40px;

  ${({ $stroke }) =>
    $stroke &&
    css`
      svg {
        path {
          stroke: ${({ theme }) => theme.color.main};
        }
      }
    `};

  ${({ $stroke }) =>
    !$stroke &&
    css`
      svg {
        path {
          fill: ${({ theme }) => theme.color.main};
        }
      }
    `};

  ${({ $active }) =>
    $active &&
    css`
      ${({ $stroke }) =>
        $stroke &&
        css`
          svg {
            path {
              stroke: ${({ theme }) => theme.color.accent};
            }
          }
        `};

      ${({ $stroke }) =>
        !$stroke &&
        css`
          svg {
            path {
              fill: ${({ theme }) => theme.color.accent};
            }
          }
        `};
    `};

  ${({ $toolTip }) =>
    $toolTip &&
    css`
      background-color: ${({ theme }) =>
        theme.color.backgroundLayoutHoverSelected};
      cursor: auto;

      &:hover {
        background-color: ${({ theme }) =>
          theme.color.backgroundLayoutHoverSelected};
      }

      ${({ $stroke }) =>
        $stroke &&
        css`
          svg {
            path {
              stroke: ${({ theme }) => theme.color.contrastMain};
            }
          }
        `};

      ${({ $stroke }) =>
        !$stroke &&
        css`
          svg {
            path {
              fill: ${({ theme }) => theme.color.contrastMain};
            }
          }
        `};
    `};

  ${({ $disabled }) =>
    $disabled &&
    css`
      cursor: auto;
      &:hover {
        background-color: transparent;
      }

      ${({ $stroke }) =>
        $stroke &&
        css`
          svg {
            path {
              stroke: ${({ theme }) =>
                theme.color.backgroundLayoutHoverNotSelected};
            }
          }
        `};

      ${({ $stroke }) =>
        !$stroke &&
        css`
          svg {
            path {
              fill: ${({ theme }) =>
                theme.color.backgroundLayoutHoverNotSelected};
            }
          }
        `};
    `};
`;

export const SpacerVertical = styled.div`
  height: 80%;
  width: 1px;
  background-color: ${({ theme }) =>
    theme.color.backgroundLayoutHoverNotSelected};
  margin: auto;
`;
